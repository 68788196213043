import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
// import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class DwndBimService {
  baseUrl = environment.basePath
  constructor(private http: HttpClient) { }

  getBimFile(){
    return this.http.get<string[]>(this.baseUrl + '/pdf/list')
  }
  getFile(body: any): Observable<any> {
    const url = `${this.baseUrl}/file/download/bim`;
    //console.log(body)
    return this.http.post(url, body);
  }
  public download(fileDetails: any, projectId: string): void {
    console.log(fileDetails)
    var blob = new Blob([fileDetails.pcdFilePath]);
    console.log(blob)
    var fileName = fileDetails.pcdFilePath.split("/")
   // console.log(fileName[5])
    var file =  new File([blob], projectId+fileDetails.scanDate+fileDetails.areaId+fileName[fileName.length-1], { type: 'blob' });
    console.log(file)
    //saveAs(file)
  }

}
