import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { PointerLockControls } from 'three/examples/jsm/controls/PointerLockControls.js';
import Stats from 'three/examples/jsm/libs/stats.module'
@Component({
  selector: 'app-pcd-overlap',
  templateUrl: './pcd-overlap.component.html',
  styleUrls: ['./pcd-overlap.component.css']
})
export class PcdOverlapComponent implements OnInit {
  objloader = new OBJLoader();
  @Input() viewSide: any
  @Input() sectionSelected: any
  @Output() loadProgress = new EventEmitter<any>()
  @Output() viewerReady = new EventEmitter<number>()
  container: any
  camera: any 
  scene: any 
  renderer:any
  cube: any
	object: any
  controls: any
  gui: any
  folderOptions: any 
  folderAnimations: any
  geometry: any
  material: any
  mesh: any
  offsetEntireBuilding = [
    {part:1,posX:16,posY:55,posZ:2},
    {part:2,posX:-20,posY:55,posZ:2},
    {part:3,posX:-18,posY:5,posZ:2},
    {part:4,posX:-20,posY:55,posZ:0},
    {part:5,posX:-20,posY:55,posZ:0}]
  viewAngle: number = 0;

  constructor() { }

  ngOnInit() {
    const scene = new THREE.Scene()
    scene.add(new THREE.AxesHelper(5))

    const light = new THREE.PointLight(0xffffff, 1000)
    light.position.set(2.5, 7.5, 15)
    scene.add(light)

    const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    )
    camera.position.z = 3

    const renderer = new THREE.WebGLRenderer()
    renderer.setSize(window.innerWidth, window.innerHeight)
    document.body.appendChild(renderer.domElement)

    const controls = new OrbitControls(camera, renderer.domElement)
    //const controls = new THREE.PointerLockControls(camera);
    controls.enableDamping = true
    var minPan = new THREE.Vector3( - 50, - 50, - 50 );
    var maxPan = new THREE.Vector3( 50, 50, 50 );
    var _v = new THREE.Vector3();
    const objLoader = new OBJLoader()
    objLoader.load(
       '/assets/models/obj/downsampled_num_10000_r_0.1p.obj',
       //'/assets/models/obj/area1_downsampled/downsampled_Area_1_part2.obj',
        (object) => {
            this.viewerReady.emit(1)
            console.log(new THREE.Box3().setFromObject( object ).getSize( new THREE.Vector3()))
            scene.add(object)
            const box = new THREE.Box3().setFromObject( object );
            const center = box.getCenter( new THREE.Vector3() );
            object.position.x += ( object.position.x - center.x );
            object.position.y += ( object.position.y - center.y );
            object.position.z += ( object.position.z - center.z );
            camera.position.set(-16,0,-5)
            this.scene=scene
            this.renderer=renderer
            this.object = object
            this.camera=camera
            const axesHelper = new THREE.AxesHelper( 10 );
            axesHelper.setColors(new THREE.Color( 0x000000),new THREE.Color( 0x008080),new THREE.Color( 0xecd178 ))
            scene.add( axesHelper );
            camera.up = new THREE.Vector3( 0, 0, 1 );
            scene.add(camera)
            scene.lookAt(0,0,90)
            // var light = new THREE.PointLight(0xffffff, 1, 100);
            // light.position.set(10, 10, 10);
            // scene.add(light);

        },
        (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
            this.loadProgress.emit((xhr.loaded / xhr.total)*100)
        },
        (error) => {
            console.log(error)
        }
    )
    var insideObject: any, outsideObject: any;

    function applyTransforms(object: any) {
      object.scale.x = 0.042;
      object.scale.y = 0.04;
    }
 
    window.addEventListener('resize', onWindowResize, false)
    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
        renderer.setSize(window.innerWidth, window.innerHeight)
        render()
    }
    const stats = new Stats()
    document.body.appendChild(stats.dom)

    function animate() {
        requestAnimationFrame(animate)

        
        controls.autoRotate
        controls.autoRotateSpeed
        controls.mouseButtons = {
          LEFT: THREE.MOUSE.ROTATE,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: THREE.MOUSE.PAN
        }
        controls.update()
        controls.addEventListener("change", function() {
          _v.copy(controls.target);
          controls.target.clamp(minPan, maxPan);
          _v.sub(controls.target);
          camera.position.sub(_v);
        })
        render()

        stats.update()
    }
    function render() {
        renderer.render(scene, camera)
    }
    animate()
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['viewSide']!==undefined && this.viewSide){
      let newdata = changes['viewSide'].currentValue;
      this.viewSide=newdata
      this.viewAngle = newdata
      if(this.viewAngle==2) {
        this.camera.position.set(0,40,0)
      } else if(this.viewAngle==3) {
        this.camera.position.set(0,0,40)
      } else if(this.viewAngle==1) {
        this.camera.position.set(40,0,0)
      }
    }
    if(changes['sectionSelected']!==undefined && this.sectionSelected){
      let newdata = changes['sectionSelected'].currentValue;
      this.sectionSelected=newdata
      this.viewAngle = newdata
      if(this.sectionSelected>=1 && this.sectionSelected<=3) {
        this.scene.remove(this.object);
        this.renderer.renderLists.dispose();
        this.renderer.dispose();
        // this.renderer.domElement.remove()
        this.loadModel(this.sectionSelected)
      } 
      // else if(this.viewAngle==3) {
      //   this.camera.position.set(0,0,90)
      // } else if(this.viewAngle==1) {
      //   this.camera.position.set(90,0,0)
      // }
    }
  }
  loadModel(fileNum: number) {
    if(fileNum == 1) {
      var file = '/assets/models/obj/area1_original/Area_1_part1.obj'
    } else if(fileNum == 2) {
      var file = '/assets/models/obj/area1_original/Area_1_part2.obj'
    } else {
      var file = '/assets/models/obj/area1_original/Area_1_part3.obj'
    }
    
    const objLoader = new OBJLoader()
    objLoader.load(
       file,
       //'/assets/models/obj/area1_downsampled/downsampled_Area_1_part2.obj',
        (object) => {
          this.viewerReady.emit(1)
            console.log(new THREE.Box3().setFromObject( object ).getSize( new THREE.Vector3() ))
            this.scene.add(object)
            const box = new THREE.Box3().setFromObject( object );
            const center = box.getCenter( new THREE.Vector3() );

            object.position.x += ( object.position.x - center.x );
            object.position.y += ( object.position.y - center.y );
            object.position.z += ( object.position.z - center.z );
            this.camera.position.set(-16,0,-5)
            this.object = object
            const axesHelper = new THREE.AxesHelper( 10 );
            axesHelper.setColors(new THREE.Color( 0x000000),new THREE.Color( 0x008080),new THREE.Color( 0xecd178 ))
            this.scene.add( axesHelper );
            this.camera.up = new THREE.Vector3( 0, 0, 1 );
            this.scene.add(this.camera)
            this.scene.lookAt(0,0,90)
            // this.geometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
            // this.material = new THREE.MeshLambertMaterial({ color: 0x2c9b73 });
            // this.mesh = new THREE.Mesh(this.geometry, this.material);
            // this.mesh.name = 'rotatingMesh'
            // this.mesh.rotation.set(45, 0, 0);
            // scene.add(this.mesh);

            // var light = new THREE.PointLight(0xffffff, 1, 100);
            // light.position.set(10, 10, 10);
            // this.scene.add(light);

        },
        (xhr) => {
            console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
            this.loadProgress.emit((xhr.loaded / xhr.total)*100)
        },
        (error) => {
            console.log(error)
        }
    )
  }
  ngOnDestroy() {
    if(this.scene && this.renderer) {
      this.scene.remove(this.object);
      this.renderer.dispose();
      this.renderer.domElement.remove()
      this.object.dispose()
      this.renderer.renderLists.dispose();
    
  }
}
}