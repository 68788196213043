import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalReadyComponent } from '../../shared/modal-ready/modal-ready.component'
import { faArrowLeft, faCube } from '@fortawesome/free-solid-svg-icons';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-viewer-main',
  templateUrl: './viewer-main.component.html',
  styleUrls: ['./viewer-main.component.css']
})
export class ViewerMainComponent implements OnInit {
  faEye = faEye
  faCube = faCube
  faArrowLeft = faArrowLeft;
  faRefresh = faRefresh
  faInfoCircle = faInfoCircle
  reset: boolean = false
  active = 1;
  goingBack: boolean = false
  pageClose: any;
  showLegend: boolean = false;
  pcdFileInfo: any;
  scanDate: any;
  projectId: any;
  viewSide: any
  stage: any 

  constructor(private _NgbModal: NgbModal, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    const dataParam = routeParams.get('dat');
    // this.fileType= "scanned" //fileType
    this.pcdFileInfo = dataParam
   //console.log(JSON.parse(this.pcdFileInfo)[0])
   this.projectId = JSON.parse(this.pcdFileInfo)[0].projectId.split("-")[1].replaceAll("_"," ")
   this.scanDate = JSON.parse(this.pcdFileInfo)[0].scanDate
   this.stage = JSON.parse(this.pcdFileInfo)[0].stage
  }
  openModal() {
    const modalRef = this._NgbModal.open(ModalReadyComponent, { backdrop : 'static', keyboard : false});
    modalRef.result.then((result) => {
      if (result) {
        this.pageClose=result
      }
    })
  }
  resetView() {
    this.reset = true
  }
  pcdViewResetDone(ev: any) {
    if(ev) {
      setTimeout(() => {this.reset = false}, 1000)
    }
  }
  toggleLegend() {
    this.showLegend = !this.showLegend
  }
  rightView() {
    this.viewSide = 3
  }
  leftView() {
    this.viewSide = 2
  }
  topView() {
    this.viewSide = 1
  }
}
