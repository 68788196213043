<nav class="navbar navbar-expand-lg pb-1  navbar-light bg-light pt-0 mb-4 shadow fixed-top" style="height: 3% !important;">
    <div class="container-fluid">
      <a class="navbar-brand" >
        <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
        <a style="color: #4E91DA; font-weight: 650;">    ZBIMS</a>    
        <a class="navbar-brand"  *ngIf = "orgName!==''" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
            Organization: {{orgName}} 
        </a> 
      </a>
      <ul class="navbar-nav" style=" margin-left: -12%;" *ngIf="displayMenu">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/overview'">Projects</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'/project-progress'">Progress</a>
        </li>
      </ul>  
      <div >
        
        <div ngbDropdown class="d-inline-block" *ngIf="displayMenu">
          <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{userInitials | uppercase }}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem (click)="signout()">Signout</button>
          </div>
        </div>
      </div>
  </div>
</nav>