<div class="modal-header">
    <p class="modal-title">Live processing sequence: File {{fileId}} ( {{scanDate}})</p>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body" style="background-color: black; color: white;">
        <div class="text-muted" *ngIf="!beDataList">No data found</div>
        <div *ngFor="let item of cvLiveDataArr"></div>
        <table class="table"  *ngIf="cvLiveDataArr">
            <tbody *ngFor="let item of cvLiveDataArr" >
                    <tr>
                        <td style="width: 20%; background-color: rgb(0, 0, 0) !important; color: white !important; border: 0;">
                            {{item.statusTimestamp.substring(0,19).replace("T"," ")}}
                        </td>
                        <td style="background-color: rgb(0, 0, 0) !important; color: white !important; border: 0;">{{item.status}} </td>
                    </tr>            
            </tbody>
        </table>
</div> 