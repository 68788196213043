import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ModalReadyComponent } from '../shared/modal-ready/modal-ready.component'

import { ProjectService, UploadService, UserService } from 'scantobim-openapi-frontend';
import { CognitoService } from 'src/app/services/cognito.service';
declare var jQuery:any
// import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// import * as JSZip from 'jszip';
// import { Subscription } from 'rxjs';
//var Filesaver = require( 'filesaver' );

@Component({
  selector: 'app-setup-project',
  templateUrl: './setup-project.component.html',
  styleUrls: ['./setup-project.component.css']
})
export class SetupProjectComponent implements OnInit {

  faArrowLeft = faArrowLeft;
  projectConfirmed: boolean = false;
  currentUser: any;
  orgName: any
  clientId: any;
  projectList: any
  projectId: string = '';
  projectExists: boolean = false;
  originalProjectId: string = '';
  message: string = '';
  projectName: string =''
  title: string = "Create Project"
  unitCountSelected: boolean = false;
  unitCount: any;
  fileName: string = '';
  revitInputs: boolean = false;
  unsupportedFile: boolean = true;
  tag: string = 'geoslam'
  messageType: string = '';
  isSaving: boolean = false
  fileId: string = 'file1'
  selectedFile: any;
  fileProcessed: boolean = false;
  subscription: any = []
  rootFileName: string = "sample";
  selectedSubdirectory: any = "unit2";
  revitSubFolder: any;
  addFiles: boolean = true;
  scheduleUploaded: boolean = true;
  fileList: any
  progress = 0
  step: number = 1
  projectUnitCount: number = 0;
  model!: NgbDateStruct 
  minDate!: NgbDateStruct 
  maxDate!: NgbDateStruct 
  projectStartDate: string = '';
  inputFieldArray: any = []
  unitAreaArr: any = [1,2,3,4,5]
  unitTitle: string = ""
  areaTitle: string = ""
  submitMsg: boolean = false;
  unitNamesAdded: boolean = false;
  unitSelectNum: number = 0;
  hierarchyArr: any
  // activeModal = inject(NgbActiveModal);
	// @Input() name: string;
  constructor(private authService: CognitoService,
              private userService: UserService,
              private uploadService: UploadService,
              private projectService: ProjectService,
              private router: Router,
              private route: ActivatedRoute,
              private _NgbModal: NgbModal) { }

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap
    const orgName = routeParams.get('orgName');
    const projName = routeParams.get('projectId');
    this.orgName=orgName
    this.currentUser = await this.authService.getCurrentUser()
    if(this.currentUser){
      var sub = this.userService.getUserDetails(this.currentUser.username).subscribe(
        (data: any) => {
          if(!jQuery.isEmptyObject(data)) {
             this.orgName = data[0].orgName
             this.clientId = data[0].clientId
             if(data[0].isAdmin==0) {
              this.router.navigate(['overview'])
            }
             this.projectService.getProjects(data[0].clientId).subscribe(
              result=> {
                if(!jQuery.isEmptyObject(result)) {
                  this.projectList = result
                  //this.submitMsg = true
                }
              })
          }
          if(data.length==0) {
            this.router.navigate(['signin'])
          }
          if(projName) {
            this.projectId = projName.toString()
            this.projectConfirmed = true
            this.loadProject()
          }
        }
      )
      this.subscription.push(sub)
    }
  }
  loadProject() {
    this.projectExists = false;
    this.message = ''
    this.originalProjectId = this.projectId
    if(this.projectId=='') {
      this.projectId = this.orgName+"-"+this.projectName.replace(/ /g,"_").toLowerCase()
    }
    var sub = this.projectService.getProjectDetails(this.projectId,this.orgName.toLowerCase())
                                          .subscribe((result: any) => {
                                            if(!jQuery.isEmptyObject(result)) { 
                                              this.projectName = result[0].projectName
                                            } 
                                           })
    this.subscription.push(sub)
    this.getFiles()
  }
  addProject() {
    if(this.projectName.length<=0) {
      this.message = 'Please add your Project name'
    } else {
      this.message = ''
      this.title = 'Project Setup'
      var projId = this.clientId+'-'+this.projectName.replace(/ /g,"_").toLowerCase()
      if(this.projectList){
        for(var i=0;i<this.projectList.length; i++) {
          if(this.projectList[i].projectId==projId) {
            this.projectExists = true
            var adminName = ''
            if(this.projectList[i].projectOwner===this.currentUser.attributes.email) {
              adminName='You can edit your project from Project List. '

            } else { 
              var sub = this.userService.getUserDetails(this.currentUser.username).subscribe(
                (data: any) => {  
                  if(!jQuery.isEmptyObject(data)) {
                    adminName=data.name
                  }
                }
              )
              this.subscription.push(sub)
            } 
            this.message = "Project: "+this.projectList[i].projectName+' exists for your organization.'+adminName
            break
          } else {
            this.projectExists=false
          }
        }
        if(this.projectExists==false) {
          //this.createNewProject()
          this.step=2
          this.projectConfirmed=true
          //this.loadProject()
        } else {  }
      }

      this.step=2
      }
  }
  addUnits() {
    this.unitCountSelected = true
    this.step=3
    for(var i=1;i<=this.projectUnitCount;i++) {
      var inputField = {
        placeholder: this.unitTitle+" "+i, name: this.unitTitle+i, unitName: '', areaCount: 1
      }
      this.inputFieldArray.push(inputField)
    }
  }
  confirmUnitNames () {
    this.step=4
    this.unitNamesAdded = true
    var unitArr = []
    for(var i=1;i<=this.inputFieldArray.length;i++) {
      var areaArr = []
      for(var j=0;j<parseInt(this.inputFieldArray[i-1].areaCount);j++) {
        var area = {
          id:this.unitTitle+i+this.areaTitle+(j+1),
          areaName: '',
          placeholder: this.areaTitle+" "+(j+1)
        }
        areaArr.push(area)
      }
      var unit = {
        name: this.unitTitle+i, unitName: this.inputFieldArray[i-1].unitName, areaCount:this.inputFieldArray[i-1].areaCount,  area: areaArr
      }
      unitArr.push(unit)
    }
    this.hierarchyArr = unitArr
    this.unitSelectNum = 0
  }
  confirmProjectDetails(){
    this.createNewProject()
  }
  confirmAreaNames() {
    for(var i = 0;i<this.hierarchyArr.length;i++) {
      for(var j=0;j<this.hierarchyArr[i].area.length;j++) {
        if(this.hierarchyArr[i].area[j].areaName==='' || this.hierarchyArr[i].area[j].areaName==null || this.hierarchyArr[i].area[j].areaName.trim()==="") {
          this.message="Enter "+this.areaTitle+" names for all "+this.unitTitle
        }
      }
    }
    if(this.message==='') {
      this.step=5
    }
  }
  changeUnitinAreaInput (e: any) {
    this.message=''
    for(var i = 0;i<this.hierarchyArr.length;i++) {
      if(e.value===this.hierarchyArr[i].unitName) {
        this.unitSelectNum = i
      }
    }

  }
  createNewProject() {
    // if(this.projectId=='') {
       this.projectId = this.projectName.replace(/ /g,"_").toLowerCase()
    // }
    var newProject = {
     projectId:this.projectId.replace(/ /g,"_").toLowerCase(),
     projectName: this.projectName,
     projectOwner: this.currentUser.username,
     clientId: this.clientId,
     orgName: this.orgName,
     unitTitle: this.unitTitle,
     areaTitle: this.areaTitle,
     startDate: this.projectStartDate,
     unitCount: this.projectUnitCount,
     unitDetails: this.hierarchyArr
     }

     var sub2 = this.projectService.createProject(newProject).subscribe((result1: any) => {
                                                 if(!jQuery.isEmptyObject(result1)) { 
                                                   this.submitMsg = true
                                                 } else {
                                                   this.message = 'Project creation failed, please try again'
                                                 }
                                                })
    this.subscription.push(sub2)
  }

  loadExistingProject() {
    this.router.navigate(['newproject', this.orgName.toLowerCase(), this.projectName.replace(/ /g,"_").toLowerCase()])
  }

  unitSelected(count: any) {
    this.projectUnitCount=count.value
    this.unitCount = Array.from({ length: (count.value - 1) / 1 + 1 },(value, index) => 1 + index * 1);
    const arrayRange = (start: number, stop: number) =>
    Array.from({ length: (count.value - 1) / 1 + 1 },(value, index) => 1 + index * 1);
    this.rootFileName="1"
  }

  fileUnit(c: any) {
    this.rootFileName = c.value
  }
  displayMessage(message: string, messageType: string){
    this.message = message
    this.messageType = messageType
  }
  getFiles() {
    var sub = this.uploadService.getFiles(this.projectId, this.currentUser.username).subscribe(result => {
      this.fileList  = result
    })
    this.subscription.push(sub)
  }
  changeDate(event: any) {
    var day, month
    var scanMonth = ("0"+this.model.month).slice(-2)
    if(this.model.day<10) {
      day = "0"+this.model.day
    } else {
      day = this.model.day
    }
    if(this.model.month<10) {
      month = "0"+this.model.month
    } else {
      month = this.model.month
    }
    this.projectStartDate = this.model.year+"-"+month+"-"+day
  }
  openModal() {
    const modalRef = this._NgbModal.open(ModalReadyComponent, { backdrop : 'static', keyboard : false});
    modalRef.result.then((result: any) => {
      if (result==1) {
        this.router.navigate(['/overview'])
      }
    })
  }

  restrict() {
    this.projectName = this.projectName.replace(/[^a-zA-Z0-9 ]/g, '')
  };

  ngOnDestroy() {
    if(this.subscription) {
      for(var i=0;i<this.subscription.length; i++) {
        this.subscription[i].unsubscribe()
      }
    }
  }
}
