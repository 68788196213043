import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { User, UserService } from 'scantobim-openapi-frontend';
import { CognitoService } from 'src/app/services/cognito.service';
//declare var $: any;  
declare var jQuery:any
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  
  cognitoUser: any
  email: string = ''
  password: string = ''
  errorMessage: string = ''
  currentUser: any;
  userd: any;
  token: any;
  clientCode: number = 0
  message: string = ''
  messageType: string = 'success'
  clientList: any;
  clientVerification: boolean = false;
  clientName: any;
  userInDB: boolean = false;
  accessLevel: string = '';
  showPassword: boolean = false
  subscription1!: Subscription
  subscription2!: Subscription
  constructor(  private ref: ChangeDetectorRef,
                private authService: CognitoService, 
                private userService: UserService, 
                private router: Router ) { }

  async ngOnInit() { 
    try {
      this.currentUser = await this.authService.getCurrentUser()
      const { attributes } = this.currentUser;
      if(this.currentUser!==undefined) {
        this.subscription2 = this.userService.getUserDetails(this.currentUser.username).subscribe(
          (data: any) => {
             if(!jQuery.isEmptyObject(data)) {
              this.userInDB = true
              this.accessLevel = data[0].clientId
             } else {
              this.userService.getOrg().subscribe(
                (result: any) => {
                  this.clientName=result[0].clientName
                  this.clientList = result
                  this.clientVerification=true
                }
              )
             }
          }
        )
        
      }
      this.ref.detectChanges();
    } catch(error:any){
      if(error == "The user is not authenticated") {
        
      } else {
        this.onProceed()
      }
    }
  }
  async onLogin(form: NgForm){
    try {
      this.cognitoUser = await this.authService.signIn(form.value.email, form.value.password)
      if (this.cognitoUser!==undefined) {
        return  
      } else {
        this.authService.setLoggedIn(true)
      }

    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  } 
  onProceed() {
    this.authService.setLoggedIn(true)
    const { attributes } = this.currentUser;
    let user: User = {
      userId: this.currentUser.username,
      name: attributes.name,
      email: attributes.email
    }
    this.router.navigate(['ui'])
  }
  onSignOut(){
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  displayMessage(message: string, messageType: string){
    this.message = message
    this.messageType = messageType
  }
  onOverview() {
    this.authService.setLoggedIn(true)
    this.router.navigate(['overview'])
  }
  checkClient(ev: any){
    this.clientName = ev.target.value

  }
  onCompanyAdminAccess() {
    const { attributes } = this.currentUser;
    var admincodefound = false
    for(var i=0; i<this.clientList.length;i++){
      if(this.clientName===this.clientList[i].clientName && this.clientCode==this.clientList[i].clientCode){
          let user: User = {
            userId: this.currentUser.username,
            name: attributes.given_name+" "+attributes.family_name,
            email: attributes.email,
            clientId: this.clientList[i].clientId,
            orgName: this.clientList[i].clientName,
            isAdmin: 1,
            }
        admincodefound=true
        
        this.subscription1 = this.userService.createUser(user).subscribe((result1: any) => {
          if(!jQuery.isEmptyObject(result1)) { 
            this.router.navigate(['overview'])
          }
        })
        break
      }
    }
    if(!admincodefound) {
      this.errorMessage = "Company code incorrect. Please recheck."
    }
  }
  onCompanyRequest() {
    const { attributes } = this.currentUser;
    for(var i=0; i<this.clientList.length;i++){
      if(this.clientName==this.clientList[i].clientName){
          let user: User = {
          userId: this.currentUser.username,
          name: attributes.given_name+" "+attributes.family_name,
          email: attributes.email,
          clientId: this.clientName.toLowerCase(),
          orgName: this.clientName,
          isAdmin: 0
        }
          this.subscription1 = this.userService.createUser(user).subscribe((result1: any) => {
            if(!jQuery.isEmptyObject(result1)) { 
              this.router.navigate(['overview'])
          }
        })
        break
      }
    }
  }
  ngOnDestroy() {
    if(this.subscription1) this.subscription1.unsubscribe()
    if(this.subscription2) this.subscription2.unsubscribe()
  }
}
