/**
 * Zontro Scan-to-BIM FE_BE APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { FileList } from '../model/fileList';
import { FileListStatus } from '../model/fileListStatus';
import { FileUrl } from '../model/fileUrl';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ApiResponse } from 'scantobim-openapi-frontend/model/apiResponse';
import { FileProcessingStatus } from 'scantobim-openapi-frontend/model/fileProcessingStatus';
import { FileProcessingStatusReq } from 'scantobim-openapi-frontend/model/fileProcessingStatusReq';
import { ViewerSoloReq } from 'scantobim-openapi-frontend/model/viewerSoloReq';
import { FileDownload } from 'scantobim-openapi-frontend/model/fileDownload';


@Injectable({providedIn: 'root'})
export class UploadService {

    protected basePath = 'http://localhost:3201/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, unitId: string, areaId: string, fileRemarks: string, scanDate: string,  observe?: 'body', reportProgress?: boolean): Observable<FileUrl>;
    public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, unitId: string, areaId: string, fileRemarks: string, scanDate: string,  observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileUrl>>;
    public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, unitId: string, areaId: string, fileRemarks: string, scanDate: string,  observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileUrl>>;
    public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, unitId: string, areaId: string, fileRemarks: string, scanDate: string,  observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addFiles.');
        }
        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling addFiles.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling addFiles.');
        }
        if (tag === null || tag === undefined) {
            throw new Error('Required parameter tag was null or undefined when calling addFiles.');
        }
        if (addedBy === null || addedBy === undefined) {
            throw new Error('Required parameter addedBy was null or undefined when calling addFiles.');
        }
        if (unitId === null || unitId === undefined) {
            throw new Error('Required parameter unitId was null or undefined when calling addFiles.');
        }
        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling addFiles.');
        }
        if (fileRemarks === null || fileRemarks === undefined) {
            throw new Error('Required parameter fileRemarks was null or undefined when calling addFiles.');
        }
        if (scanDate === null || scanDate === undefined) {
            throw new Error('Required parameter scanDate was null or undefined when calling addFiles.');
        }
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        const consumes: string[] = [
            'multipart/form-data'
        ];
        const canConsumeForm = this.canConsumeForm(consumes);
        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }
        if (projectId !== undefined) {
            formParams = formParams.append('projectId', <any>projectId) as any || formParams;
        }
        if (fileId !== undefined) {
            formParams = formParams.append('fileId', <any>fileId) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }
        if (tag !== undefined) {
            formParams = formParams.append('tag', <any>tag) as any || formParams;
        }
        if (addedBy !== undefined) {
            formParams = formParams.append('addedBy', <any>addedBy) as any || formParams;
        }
        if (unitId !== undefined) {
            formParams = formParams.append('unitId', <any>unitId) as any || formParams;
        }
        if (areaId !== undefined) {
            formParams = formParams.append('areaId', <any>areaId) as any || formParams;
        }
        if (fileRemarks !== undefined) {
            formParams = formParams.append('fileRemarks', <any>fileRemarks) as any || formParams;
        }
        if (scanDate !== undefined) {
            formParams = formParams.append('scanDate', <any>scanDate) as any || formParams;
        }
        var a = this.httpClient.request<ApiResponse>('post',`${this.basePath}/file/add`,
            {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                //body: formParams,
                withCredentials: this.configuration.withCredentials,
                //headers: headers.append('Content-Type', 'multipart/form-data'),
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
        return a
    }

    public getFileStatusUI(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FileListStatus>>;
    public getFileStatusUI(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileListStatus>>>;
    public getFileStatusUI(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileListStatus>>>;
    public getFileStatusUI(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getFileStatusUI.');
        }
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        const consumes: string[] = [
        ];
        return this.httpClient.request<Array<FileListStatus>>('get',`${this.basePath}/file/get/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getFiles(projectId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FileList>>;
    public getFiles(projectId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileList>>>;
    public getFiles(projectId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileList>>>;
    public getFiles(projectId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getFiles.');
        }
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getFiles.');
        }
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        const consumes: string[] = [
        ];
        return this.httpClient.request<Array<FileList>>('get',`${this.basePath}/file/get/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public getProcessingStatusRapid(body: FileProcessingStatusReq, observe?: 'body', reportProgress?: boolean): Observable<Array<FileProcessingStatus>>;
    public getProcessingStatusRapid(body: FileProcessingStatusReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileProcessingStatus>>>;
    public getProcessingStatusRapid(body: FileProcessingStatusReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileProcessingStatus>>>;
    public getProcessingStatusRapid(body: FileProcessingStatusReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getProcessingStatusRapid.');
        }
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.request<Array<FileProcessingStatus>>('post',`${this.basePath}/ui/files/processing-status`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getProcessingStatusDwnld(body: FileProcessingStatusReq, observe?: 'body', reportProgress?: boolean): Observable<Array<FileProcessingStatus>>;
    public getProcessingStatusDwnld(body: FileProcessingStatusReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileProcessingStatus>>>;
    public getProcessingStatusDwnld(body: FileProcessingStatusReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileProcessingStatus>>>;
    public getProcessingStatusDwnld(body: FileProcessingStatusReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling getProcessingStatusRapid.');
        }
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }
        return this.httpClient.request<Array<FileProcessingStatus>>('post',`${this.basePath}/ui/files/processing-status/dwnld`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getFileStatusRapid(fileId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ApiResponse>>;
    public getFileStatusRapid(fileId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApiResponse>>>;
    public getFileStatusRapid(fileId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApiResponse>>>;
    public getFileStatusRapid(fileId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileId === null || fileId === undefined) {
            throw new Error('Required parameter fileId was null or undefined when calling getFileStatusRapid.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ApiResponse>>('get',`${this.basePath}/ui/files/poll-status/${encodeURIComponent(String(fileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public downloadBim(body: ViewerSoloReq, observe?: 'body', reportProgress?: boolean): Observable<FileDownload>;
    public downloadBim(body: ViewerSoloReq, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileDownload>>;
    public downloadBim(body: ViewerSoloReq, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileDownload>>;
    public downloadBim(body: ViewerSoloReq, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling downloadBim.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'multipart/form-data',
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request('post',`${this.basePath}/file/download/bim`,
            {
                body: body,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
