import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService, UserService, UploadService, ViewerService } from 'scantobim-openapi-frontend';
import { CognitoService } from 'src/app/services/cognito.service';
import { NgbModal, NgbAccordionModule, NgbTooltipModule, NgbProgressbar, NgbAccordion  } from '@ng-bootstrap/ng-bootstrap';
import { ModalReadyComponent } from '../shared/modal-ready/modal-ready.component';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Observable, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { ModalRapidStatusComponent } from '../modal-rapid-status/modal-rapid-status.component';
import { DwndCsvService } from 'src/app/shared/services/dwnd-csv.service';
import { DwndBimService } from 'src/app/shared/services/dwnd-bim.service';
// import * as JSZip from 'jszip';
// import { saveAs } from 'file-saver';
declare var jQuery:any
//import * as $ from 'jquery'

@Component({
  selector: 'app-progress-main',
  templateUrl: './progress-main.component.html',
  styleUrls: ['./progress-main.component.css']
})
export class ProgressMainComponent implements OnInit {

  @ViewChild('acc') accordionComponent!: NgbAccordion;
  faArrowLeft = faArrowLeft;
  currentUser: any
  projectList: any
  orgName: any;
  clientId: any;
  noAccessToProjects: boolean = false
  userIsAdmin: boolean = false;
  userIsEditor: boolean = false;
  subscription: any = []
  unitAreaDetails: any;
  fileList: any;
  projectId: string = ""
  currentStep: number = 0;
  showProgress: boolean = false
  selectedFileProgress: number = 0
  public screenWidth: any;
  public screenHeight: any;
  smallScreen: boolean = false
  selectedScan: any
  selectedProjectName: string = ""
  fileLastModifiedDate: string = ""
  areaId: any;
  selectedPanel: any;
  stopPolling = new Subject()
  stopPolling2 = new Subject()
  cvLiveMsg$!: any
  cvLiveMsg: any
  cvLiveDataArr: any[] = []
  fileLiveMsg$!: any
  fileLiveDataArr: any[] = []
  private stopProgressStatusPolling = new Subject()
  progressStatusData$!: Observable<any>;
  fileDw: any;
  bimFilePath: string = '';
  bimFileName: string = '';

  constructor(private authService: CognitoService, 
    private projectService: ProjectService, 
    private userService: UserService,
    private uploadService: UploadService,
    private viewerService: ViewerService,
    private route: ActivatedRoute,
    private dwndCsvService: DwndCsvService,
    private dwndBimService: DwndBimService,
    private router: Router,
    private _NgbModal: NgbModal) { }
  
  @HostListener('window:resize', ['$event']) 
    onResize(event: any) {
      this.checkScreenSize()
    }

  async ngOnInit() {
    try {
      this.currentUser = await this.authService.getCurrentUser() 
      this.getProjects()
      this.checkScreenSize()
      const routeParams = this.route.snapshot.paramMap
      this.selectedPanel = routeParams.get('id');
    } catch(error:any){
      console.log(error) 
      if(error=="The user is not authenticated") {
        this.router.navigate(['signin'])
      }
    }
  }

  getProjects() {
    var sub1 = this.userService.getUserDetails(this.currentUser.username).subscribe(
      (data: any) => {  
        if(!jQuery.isEmptyObject(data)) {
          this.orgName = data[0].orgName
          this.clientId = data[0].clientId
          if(data[0].isAdmin==1) {
            this.userIsAdmin=true
          } else if(data[0].isEditor==1) {
            this.userIsEditor=true
          }
          var sub3 = this.projectService.getProjects(data[0].clientId).subscribe(
            result=> {
              if(!result || result.length==0) {
                this.noAccessToProjects = true
                this.router.navigate(['overview'])
              } else {
                this.noAccessToProjects = false
                this.projectList = result
                // this.accordionComponent.expand("2")
                // if(this.selectedPanel==="2"){
                //   this.accordionComponent.collapseAll()
                //   this.accordionComponent.toggle("2")
                // }
              }
            }
          )
          this.subscription.push(sub3)
        }
        if(data.length==0) {
          this.router.navigate(['signin'])
        }
      }
    )
    this.subscription.push(sub1)
  }

  getFiles() {
    var sub4 = this.uploadService.getFiles(this.projectId, this.currentUser.username).subscribe(result => {
      this.fileList  = result
    })
    for(var i=0;i<this.projectList.length;i++) {
      if(this.projectId===this.projectList[i].projectId) {
        this.selectedProjectName = this.projectList[i].projectName
        break
      }
    }
    this.subscription.push(sub4)
  }

  toggleAccordian(event: any) {
    this.projectId = this.projectList[parseInt(event.panelId)].projectId

    // this.progressStatusData$ = timer(0, 2000).pipe(
    //   switchMap(async (_) => this.getFiles()),
    //   takeUntil(this.stopProgressStatusPolling)
    // )
    this.showProgress=false
    this.selectedScan = []
    this.getFiles()
  }

  getFileProgress(item: any) {
    this.stopPolling.next('')
    this.stopPolling2.next('')
    this.showProgress=true
    this.selectedScan = item
    this.currentStep=0
    // if(!item.beUpdateDate || item.beUpdateDate.substring(0,10) < item.uploadDate.substring(0,10)) {
    //   this.fileLastModifiedDate = item.uploadDate.substring(0,10)
    // } else if (item.beUpdateDate.substring(0,10) > item.uploadDate.substring(0,10))  {
    //   this.fileLastModifiedDate = item.beUpdateDate.substring(0,10)
    // }  
   // this.areaId = item.areaId.substring(item.unitId.length,item.areaId.length)
    this.pollLiveProcessingData()
    this.pollLiveFileStatus()
  }

  openModal() {
    const modalRef = this._NgbModal.open(ModalReadyComponent, { backdrop : 'static', keyboard : false});
    modalRef.result.then((result: any) => {
      if (result==1) {
        this.router.navigate(['/overview'])
      }
    })
  }

  openLiveProcessing() {
    const modalRef = this._NgbModal.open(ModalRapidStatusComponent, {backdrop : 'static', keyboard : false, scrollable: true, size: 'xl', windowClass: 'livemodalclass'  })
    modalRef.componentInstance.projectId = this.selectedScan.projectId;
    modalRef.componentInstance.fileId = this.selectedScan.id;
    modalRef.componentInstance.scanDate = this.selectedScan.scanDate;
   }

  updateProgressBar() {
    var progressPercentage = Math.round(((this.currentStep - 1) / 6) * 100);
    if(progressPercentage>0) {
      this.selectedFileProgress=progressPercentage
    } else {
      this.selectedFileProgress = 0
    }
  }

  checkScreenSize() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if(this.screenWidth<1536) {
      this.smallScreen=true
    } else if(this.screenWidth>=1536){
      this.smallScreen = false
    }
  }

  onViewScannedPCD(data: any) {
    let viewerReq = {
      projectId: data.projectId,
      fileId: data.id,
      scanDate: data.scanDate,
      stage: "stage_3"
    }
    var sub5 = this.viewerService.getPcdWhole(viewerReq).subscribe((resultPCD: any) => {
      this.router.navigate(['/viewer/scanned',{dat:JSON.stringify(resultPCD)}])  
    })
    this.subscription.push(sub5)
  }

  onViewSegmentedPCD(data: any) {
    let viewerReq = {
      projectId: data.projectId,
      fileId: data.id,
      scanDate: data.scanDate,
      stage: "stage_5"
    }
    var sub6 = this.viewerService.getPcdWhole(viewerReq).subscribe((resultPCD: any) => {
      this.router.navigate(['/viewer/segmented',{dat:JSON.stringify(resultPCD)}])  
    })
    this.subscription.push(sub6)
  }

  pollLiveProcessingData() {
    let req = {
      projectId:this.selectedScan.projectId,
      fileId: this.selectedScan.id, 
      scanDate: this.selectedScan.scanDate
    }   
    if(this.currentStep!=7) {
      this.cvLiveMsg$ = timer(0, 500).pipe(
        switchMap(_ => this.uploadService.getProcessingStatusRapid(req)),
        takeUntil(this.stopPolling)
      )
      let subscription3 = this.cvLiveMsg$.subscribe( {
        next: (result: any[]) => {
          if(result && result.length>0) {
            this.cvLiveDataArr = result
          } else {this.cvLiveDataArr = []}
        },
        error: (e: any) => console.log(e)
      })
      this.subscription.push(subscription3)
    }
    if(this.currentStep==7) {
      this.stopPoll()
    }
  }

  pollLiveFileStatus() {
    this.fileLiveMsg$ = timer(0, 500).pipe(
      switchMap(_ => this.uploadService.getFileStatusRapid(this.selectedScan.id)),
      takeUntil(this.stopPolling2)
    )
    let subscription4 = this.fileLiveMsg$.subscribe( {
      next: (resultFile: any[]) => {
        if(resultFile && resultFile.length>0) {
          this.fileLiveDataArr = resultFile
          if(this.fileLiveDataArr[0].beUpdateDate==null) {
            this.fileLastModifiedDate = 'None'
          } else { this.fileLastModifiedDate = this.fileLiveDataArr[0].beUpdateDate.substring(0,10)}

          this.currentStep = this.fileLiveDataArr[0].step
          this.updateProgressBar()
          if(this.currentStep==7) {
            this.stopPoll()
            this.downloadBIM(this.selectedScan)
          } else {
            this.bimFilePath = ''
            this.bimFileName = ''
          }
        } else {this.fileLiveDataArr = []}
      },
      error: (e: any) => console.log(e)
    })
    this.subscription.push(subscription4)
  }
  
  onDownloadStatusList() {
    var sub5 = undefined
    let req = { projectId:this.selectedScan.projectId, fileId: this.selectedScan.id, scanDate: this.selectedScan.scanDate }  
    sub5 = this.uploadService.getProcessingStatusDwnld(req).subscribe(result => {
      var dt = this.selectedScan.scanDate.substring(0,19).replace("T"," ")
      var fname="ZBIMS_"+this.selectedScan.projectId.split('-')[1]+"_"+dt+"_"+this.selectedScan.fileName.split(".zip")[0]
      this.dwndCsvService.exportToCsv(fname,result)
    })
  }

  downloadBIM(data: any) {
    this.bimFilePath = ''
    this.bimFileName = ''
    let viewerReq = {
      projectId: data.projectId,
      fileId: data.id,
      scanDate: data.scanDate,
      stage: "stage_7"
    }
    var sub7 = this.dwndBimService.getFile(viewerReq).subscribe((resultBIM: any) => {
      this.bimFilePath = "/assets/"+resultBIM.pcdFilePath
      this.bimFileName = "BIM-"+this.projectId+"-"+this.selectedScan.fileName.split(".zip")[0]+".rvt"
    })
    this.subscription.push(sub7)
  }

stopPoll() {
  this.stopProgressStatusPolling.next('')
  this.stopPolling.next('')
  this.stopPolling2.next('')
}

  ngOnDestroy() {
    this.stopProgressStatusPolling.next('')
    this.stopPolling.next('')
    this.stopPolling2.next('')
    if(this.subscription) {
      for(var i=0;i<this.subscription.length; i++) {
        this.subscription[i].unsubscribe()
      }
    }
  }
}
