import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'scantobim-openapi-frontend';
import { CognitoService } from 'src/app/services/cognito.service';

@Component({
  selector: 'app-changepwd',
  templateUrl: './changepwd.component.html',
  styleUrls: ['./changepwd.component.css']
})
export class ChangepwdComponent implements OnInit {
  currentUser: any
  subscription2!: Subscription
  errorMessage: string = ''
  data: any;
  constructor(private userService: UserService, private authService: CognitoService, private ref: ChangeDetectorRef, ) { }

  async ngOnInit() {
    
  }
  

}
