<head>
    <meta name="viewport" content=
        "width=device-width, initial-scale=1.0" />
</head>

<body style='background-image:url("assets/images/Building3-1.jpg"); height: 100vh;  background-size: cover; background-repeat: no-repeat;'>
    <div class="wind text-start">
        <div class="d-flex aligns-items-center justify-content-center card w-75 mx-auto" style="padding-left: 5%; padding-top: 20px; padding-bottom: 10px;"> 
    <!-- <img class="mb-4" src="assets/images/logo.png" alt="" width="28" height="28"><a style="color: #4E91DA; font-weight: 650;">    ZBIMS</a> -->
    <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
        <a style="color: #4E91DA; font-weight: 650;">    ZBIMS</a>     
    <div *ngIf="!verify"> 
        <main class="form-signup "> 
            <div class="input-group mb-3">
                <form (ngSubmit)="signUpWithCognito(signupForm)" #signupForm="ngForm" >
                    <div *ngIf="errorMessage" type="danger"><p style=" color: red;"> {{errorMessage }}</p></div>
                    <h1 class="h3 mb-4 pt-10 fw-normal" style="color: #64b5f6; padding-top: 10%;">Sign up</h1>
                    <p class="mt-3 mb-3 text-muted" style="font-size: 12px;"> Already have an account?  <a [routerLink]="['/signin']"> Sign in</a></p>
                    <div class="form-floating mb-2">
                        <input type="email" class="form-control " size="45" name= "email" id="floatingInput" [(ngModel)]="cognitoUser.email" placeholder="name@example.com" required>
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="name" class="form-control " name= "name" id="givenName" [(ngModel)]="cognitoUser.givenName" placeholder="Full Name" required>
                        <label for="givenName">First Name</label>
                    </div>
                    <div class="form-floating mb-2">
                        <input type="name" class="form-control " name= "name" id="familyName" [(ngModel)]="cognitoUser.familyName" placeholder="Full Name" required>
                        <label for="familyName">Last Name</label>
                    </div>
                    <div class="form-floating mt-2 mb-2">
                        <input [type]="showPassword ? 'text' : 'password'" class="form-control form-control-sm" name="password" id="floatingPassword" [(ngModel)]="password" placeholder="Password" required>
                        <label for="floatingPassword">Password</label>
                        <div>
                            <input type="checkbox" [checked]="showPassword" (change)="showPassword=!showPassword" name="givenName">
                        Show 
                        </div>
                    </div>    
                    <div class="form-floating mt-2 mb-2">
                        <input type="password" class="form-control form-control-sm" name="password" id="floatingPassword2" [(ngModel)]="password2" placeholder="Password" required>
                        <label for="floatingPassword2">Confirm Password</label>
                    </div>  
                    <button class="w-20 btn btn-primary" type="submit">Sign up</button>
                </form>
            </div>
        </main>
    </div> 
    <div *ngIf="verify">
        <div  class="row">
            <p class="mt-5 mb-3 text-muted" style="font-size: 12px;"> </p>
            <div *ngIf="errorMessage" type="danger"><p style=" color: red;"> {{errorMessage }}</p></div>
            <h1 class="h3 mb-4 pt-10 fw-normal" style="color: #64b5f6;">Verification</h1>
            <p class="mt-3 mb-3 text-muted" style="font-size: 12px;"> Please enter the verification code sent to your email {{toVerify}}</p>
            <div id="form-floating" class="mr-2">
                <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c1" [(ngModel)]="c1" pattern="[0-9]{1}" (keyup)="moveToNext($event)" />
                <input class="inpt"  type="text" maxLength="1" size="1" min="0" max="9" name="c2" [(ngModel)]="c2" pattern="[0-9]{1}" (keyup)="moveToNext($event)"/>
                <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c3" [(ngModel)]="c3" pattern="[0-9]{1}" (keyup)="moveToNext($event)"/>
                <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c4" [(ngModel)]="c4" pattern="[0-9]{1}" (keyup)="moveToNext($event)"/>
                <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c5" [(ngModel)]="c5" pattern="[0-9]{1}" (keyup)="moveToNext($event)"/>
                <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c6" [(ngModel)]="c6" pattern="[0-9]{1}" (keyup)="moveToNext($event)"/>
            </div>
        </div>  
        <div class="row m-10">
            <div class="form-floating mt-2 mb-2">
                <div class="btn-group" role="group" aria-label="First group">
                  <button class="w-30 btn btn-primary mr-3"  type="submit" (click)="onVerify()" >Verify</button>
                  <button class="w-30 btn btn-primary mr-3" type="submit" (click)="onResendCode()">Resend code</button>
                </div>
            </div>
        </div>
        <div class="row">
            <p class="mt-3 mb-3 text-muted" style="font-size: 12px;">The verification code sent to your email is valid for <a style="color: #64b5f6; font-weight: bold;">24 hours</a></p><br />
        </div>
    </div> 
    <div *ngIf="msg">
        <div  class="row">
            <p class="mt-3 mb-3 text-muted" style="font-size: 18px;"> Email verification successful</p>
        </div>
        <div class="row m-10">
            <div class="form-floating mt-2 mb-2">
                <button class="w-30 btn btn-primary mr-3"  type="submit" (click)="onVerifySuccess()" >Next</button> 
            </div>
        </div>
    </div>  
    <p class="mt-5 mb-3 text-muted" style="font-size: 12px;">&copy; <a href="#"> Terms and Conditions</a> | <a href="#"> Privacy Policy</a></p>     
</div>
</div>
</body>