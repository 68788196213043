<app-pcd-overlap [viewSide] = 'viewSide' [sectionSelected] = 'sectionSelected' (loadProgress) = "loadProgressPercent($event)" (viewerReady)="closeModal($event)"></app-pcd-overlap>
<!-- <div style="position: absolute; top: 50px; left: 50px; z-index: 100;">
<tr><td>Top-left</td><td>Top-right</td></tr><tr><td>Bottom-right</td><td>Bottom-left</td></tr></div> -->
<div class="controls">
    <div class="row justify-content-end">
        <div ngbDropdown class="d-inline-block">
			<button type="button" class="btn btn-dark" id="dropdownBasic1" ngbDropdownToggle>
				Section
			</button>
			<div ngbDropdownMenu aria-labelledby="dropdownBasic1">
				<button ngbDropdownItem (click)="selectSec1()">Section 1</button>
				<button ngbDropdownItem (click)="selectSec2()">Section 2</button>
				<button ngbDropdownItem (click)="selectSec3()">Section 3</button>
			</div>
		</div>
    </div>
    <div class="row pt-2 justify-content-end">
        <button style="width: 40px; height: 40px;" class="btn btn-primary" (click)="leftView()" ><fa-icon [icon]="faCube"></fa-icon></button>
    </div> 
    <div class="row pt-2 justify-content-end">
        <button  class="btn btn-primary" style="transform: rotate(90deg); width: 40px; height: 40px;" (click)="topView()" ><fa-icon [icon]="faCube"  style="transform: rotate(90deg);"></fa-icon></button>
    </div>  

    <!-- <button>pan</button>
    <button>rotate</button>
    <button>zoom</button> -->
    <div class="row pt-2 justify-content-end">
        <button  class="btn btn-primary"  style="transform: rotate(180deg); width: 40px; height: 40px;" (click)="rightView()"><fa-icon [icon]="faCube"></fa-icon></button>
    </div>
</div>  