import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
//import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
//import { MapControls } from 'three/examples/jsm/controls/MapControls.js';
import Stats from 'three/examples/jsm/libs/stats.module'
import { ActivatedRoute, Router } from '@angular/router';
//import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
//import { ArcballControls } from 'three/examples/jsm/controls/ArcballControls.js';
import {  NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { ModalProgressComponent } from '../../shared/modal-progress/modal-progress.component'
//var ScreenQuad = require('three-screen-quad')(THREE)

@Component({
  selector: 'app-obj-viewer',
  templateUrl: './obj-viewer.component.html',
  styleUrls: ['./obj-viewer.component.css']
})
export class ObjViewerComponent implements OnInit {
  @Input() resetView: any
  @Input() pageClose: any
  @Input() pcdFileInfo: any;
  @Input() viewSide: any
  @Output() resetViewFlip = new EventEmitter<any>()
  objloader = new OBJLoader();
  container: any
  camera: any 
  scene: any 
  renderer:any
  cube: any
	object: any
  controls: any
  gui: any
  folderOptions: any 
  folderAnimations: any
  fileType: any
  center!: THREE.Vector3;
  viewAngle: number = 0;

  step: any;
  progressPercent: any;
  modalRef: any

  //For Sectional Clipping

  planes: any 
  planeObjects: any 
  planeHelpers: any
	clock: any
  box: any
  boxSize: any
  globalPlaneX: any
  globalPlaneHelperX: any;
  globalPlaneY: any
  globalPlaneHelperY: any;
  globalPlaneZ: any
  globalPlaneHelperZ: any;

  constructor(private router: Router, private route: ActivatedRoute,private _NgbModal: NgbModal) { }

  ngOnInit() {
    const params = {
      animate: true,
      clipMaster: {
        Enable: false,
        Reset: false
      },
      globalX: {
        constant: 0,
        negated: false,
        Show: false
      },
      globalY: {
        constant: 0,
        negated: false,
        Show: false
      },
      globalZ: {
        constant: 0,
        negated: false,
        Show: false
      }
    };
    const scene = new THREE.Scene()
    scene.add(new THREE.AxesHelper(5))
    THREE.Object3D.DEFAULT_UP.set(1, 0, 1);
    this.openModal()
    const light = new THREE.PointLight(0xffffff, 1000)
    light.position.set(2.5, 7.5, 15)
    scene.add(light)

    const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    )
    camera.position.z = 3

    const renderer = new THREE.WebGLRenderer({
    })
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(innerWidth,innerHeight)
    document.body.appendChild(renderer.domElement)

    const controls = new OrbitControls(camera, renderer.domElement)
    const objLoader = new OBJLoader()
    var fileName = ''
    let filePath=JSON.parse(this.pcdFileInfo)[0].pcdFilePath
    //let basePCDPath = "C:/Users/deban/dev/ScanToBIM_UI_23/"
    let basePCDPath = "/assets/"
    if(this.step==="stage_3") {
      fileName = basePCDPath+filePath
      //fileName = "https://storage.googleapis.com/as-bucket1234112/room_181.obj"
    } else if(this.step==="stage_5") {
      fileName = basePCDPath+filePath
      
    } else {
      this.router.navigate(['/overview'])
    }
    var minPan = new THREE.Vector3( - 50, - 50, - 50 );
    var maxPan = new THREE.Vector3( 50, 50, 50 );
    var _v = new THREE.Vector3();
    objLoader.load(
       fileName,
        (object) => {
          scene.add(object)
          this.box = new THREE.Box3().setFromObject( object )
          const center = this.box.getCenter( new THREE.Vector3() );
          object.position.x += ( object.position.x - center.x );
          object.position.y += ( object.position.y - center.y );
          object.position.z += ( object.position.z - center.z );
          console.log(center)
          camera.position.set(20,0,90)
          this.scene=scene
          this.renderer=renderer
          this.object = object
          this.camera=camera
          this.center = center
          this.scene.lookAt(0,0,90)
          this.closeModal(1)

          //sectional planes
          this.globalPlaneX = new THREE.Plane( new THREE.Vector3( - 1, 0, 0 ), 10 );
          this.globalPlaneHelperX = new THREE.PlaneHelper( this.globalPlaneX, this.box.max.x+10, 0xff0000 ) ;
          this.globalPlaneHelperX.visible = false;
          scene.add( this.globalPlaneHelperX );

          this.globalPlaneY = new THREE.Plane( new THREE.Vector3( 0, - 1, 0 ), 10 );
          this.globalPlaneHelperY = new THREE.PlaneHelper( this.globalPlaneY, this.box.max.y+10, 0x00ff00 ) ;
          this.globalPlaneHelperY.visible = false;
          scene.add( this.globalPlaneHelperY );

          this.globalPlaneZ = new THREE.Plane( new THREE.Vector3( 0, 0, - 1 ), 10 );
          this.globalPlaneHelperZ = new THREE.PlaneHelper( this.globalPlaneZ, this.box.max.z+10, 0x0000ff ) ;
          this.globalPlaneHelperZ.visible = false;
          scene.add( this.globalPlaneHelperZ );

          const globalPlanesX = [ this.globalPlaneX ],
					Empty = Object.freeze( [] );
          const globalPlanesY = [ this.globalPlaneY ]
          const globalPlanesZ = [ this.globalPlaneZ ]
          renderer.clippingPlanes = Empty; // GUI sets it to globalPlanes
          const gui = new GUI();
          gui.domElement.style.marginTop='105px'
          //const emptySpace = gui.addFolder( '   ' );
          const globalX = gui.addFolder( 'X Axis' );
          globalX.add( params.globalX, 'Show' ).onChange( v => {
            //this.renderer.clippingPlanes=v ? globalPlanesX: Empty ; 
            this.globalPlaneHelperX.visible = v
          });
          globalX.add( params.globalX, 'constant' ).min( Math.round(-this.box.max.x-2) ).max( Math.round(this.box.max.x+2) ).onChange( d => this.globalPlaneX.constant = d );
          globalX.open();
          const globalY = gui.addFolder( 'Y Axis' );
          globalY.add( params.globalY, 'Show' ).onChange( v => {
            this.globalPlaneHelperY.visible = v;
          });
          globalY.add( params.globalY, 'constant' ).min( Math.round(-this.box.max.y-2) ).max( Math.round(this.box.max.y+2) ).onChange( d => this.globalPlaneY.constant = d );
          globalY.open();
          
          const globalZ = gui.addFolder( 'Z Axis' );
          globalZ.add( params.globalZ, 'Show' ).onChange( v => {
            this.globalPlaneHelperZ.visible = v
          });
          globalZ.add( params.globalZ, 'constant' ).min( Math.round(-this.box.max.z-2) ).max( Math.round(this.box.max.z+2) ).onChange( d => { this.globalPlaneZ.constant = d })
          globalZ.open();

          const clipMaster = gui.addFolder( 'Sectional View' );
          clipMaster.add(params.clipMaster,'Enable').onChange(v => {
            if(v) {
              this.renderer.clippingPlanes=[this.globalPlaneZ, this.globalPlaneY, this.globalPlaneX]
              this.globalPlaneHelperX.visible = true
              this.globalPlaneHelperY.visible = true
              this.globalPlaneHelperZ.visible = true
              params.globalX.Show = true
              params.globalY.Show = true
              params.globalZ.Show = true
              globalX.controllers[0].updateDisplay()
              globalY.controllers[0].updateDisplay()
              globalZ.controllers[0].updateDisplay()
            } else {
              this.renderer.clippingPlanes = Empty
              this.globalPlaneHelperX.visible = false
              this.globalPlaneHelperY.visible = false
              this.globalPlaneHelperZ.visible = false
              params.globalX.Show = false
              params.globalY.Show = false
              params.globalZ.Show = false
              globalX.controllers[0].updateDisplay()
              globalY.controllers[0].updateDisplay()
              globalZ.controllers[0].updateDisplay()
            }
            this.renderer.clippingPlanes=v ? [this.globalPlaneZ, this.globalPlaneY, this.globalPlaneX]: Empty 
          })
          clipMaster.open();
        },
        (xhr) => {
            this.loadProgressPercent(((xhr.loaded / xhr.total)*100))
        },
        (error) => {
            console.log(error)
        }
    )




    // var object = new THREE.Group();
    // scene.add( object );  
     //sectional planes end

    window.addEventListener('resize', onWindowResize, false)
    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
        renderer.setSize(window.innerWidth, window.innerHeight)
        render()
    }
    const stats = new Stats()
    document.body.appendChild(stats.dom)

    const animate = () => {
        requestAnimationFrame(animate)

        
        controls.autoRotate
        controls.autoRotateSpeed
        // controls.keys = {
        //   LEFT: 'ArrowLeft', //left arrow
        //   UP: 'ArrowUp', // up arrow
        //   RIGHT: 'ArrowRight', // right arrow
        //   BOTTOM: 'ArrowDown' // down arrow
        //}
        controls.mouseButtons = {
          LEFT: THREE.MOUSE.ROTATE,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: THREE.MOUSE.PAN
        }
        
        controls.update() 
        controls.addEventListener("change", () => {
          _v.copy(controls.target);
          controls.target.clamp(minPan, maxPan);
          _v.sub(controls.target);
          camera.position.sub(_v);
          this.controls=controls
      })

      render()

      stats.update()
    }

    
    function render() {
        renderer.render(scene, camera)
    }
    animate()
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['pcdFileInfo']!==undefined && this.pcdFileInfo){
      let newdata = changes['pcdFileInfo'].currentValue;
      this.pcdFileInfo=newdata
      if (this.pcdFileInfo) {
        this.step =JSON.parse(this.pcdFileInfo)[0].stage
      }
      
    }
    if(changes['resetView']!==undefined && this.resetView){
      let newdata = changes['resetView'].currentValue;
      this.resetView=newdata
      if (this.resetView) {
        this.controls.reset();
        this.camera.position.set(200,0,40)
        this.resetViewFlip.emit(true)
      } else if(!this.resetView) {
        this.resetViewFlip.emit(false)
      }
    }
    if(changes['pageClose']!==undefined && this.pageClose){
      let newdata = changes['pageClose'].currentValue;
      this.pageClose=newdata
      if(this.pageClose==1) {
        if(this.scene) {
          this.scene.remove(this.object);
        }
        if(this.renderer) {
          this.renderer.dispose();
          this.renderer.domElement.remove()
        }

        this.router.navigate(['/project-progress'])
      }
    }
    if(changes['viewSide']!==undefined && this.viewSide){
      let newdata = changes['viewSide'].currentValue;
      this.viewSide=newdata
      this.viewAngle = newdata
      if(this.viewAngle==2) {
        this.camera.position.set(0,40,0)
      } else if(this.viewAngle==3) {
        this.camera.position.set(0,0,40)
      } else if(this.viewAngle==1) {
        this.camera.position.set(40,0,0)
      }
    }
  }
  openModal() {
    this.modalRef = this._NgbModal.open(ModalProgressComponent, { centered: true, backdrop : 'static', keyboard : false});
  }
  loadProgressPercent(ev: number) {
    this.progressPercent = Math.round(ev)
    this.modalRef.componentInstance.progressPercent = this.progressPercent;
  }
  closeModal(ev: number) {
    if(ev==1) {
      this._NgbModal.dismissAll("completed")
    }
  }

  createPlaneStencilGroup( geometry: any, plane: any, renderOrder: any ) {

    const group = new THREE.Group();
    const baseMat = new THREE.MeshBasicMaterial();
    baseMat.depthWrite = false;
    baseMat.depthTest = false;
    baseMat.colorWrite = false;
    baseMat.stencilWrite = true;
    baseMat.stencilFunc = THREE.AlwaysStencilFunc;

    // back faces
    const mat0 = baseMat.clone();
    mat0.side = THREE.BackSide;
    mat0.clippingPlanes = [ plane ];
    mat0.stencilFail = THREE.IncrementWrapStencilOp;
    mat0.stencilZFail = THREE.IncrementWrapStencilOp;
    mat0.stencilZPass = THREE.IncrementWrapStencilOp;

    const mesh0 = new THREE.Mesh( geometry, mat0 );
    mesh0.renderOrder = renderOrder;
    group.add( mesh0 );

    // front faces
    const mat1 = baseMat.clone();
    mat1.side = THREE.FrontSide;
    mat1.clippingPlanes = [ plane ];
    mat1.stencilFail = THREE.DecrementWrapStencilOp;
    mat1.stencilZFail = THREE.DecrementWrapStencilOp;
    mat1.stencilZPass = THREE.DecrementWrapStencilOp;

    const mesh1 = new THREE.Mesh( geometry, mat1 );
    mesh1.renderOrder = renderOrder;

    group.add( mesh1 );

    return group;

  }
  ngOnDestroy() {
    if(this.scene && this.renderer) {
      this.scene.remove(this.object);
      this.renderer.dispose();
      this.renderer.domElement.remove()
      this.globalPlaneX.dispose()
      this.globalPlaneY.dispose()
      this.globalPlaneZ.dispose()
      this.globalPlaneHelperX.dispose()
      this.globalPlaneHelperY.dispose()
      this.globalPlaneHelperZ.dispose()
    }
  }
}
