import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './components/auth/signup/signup.component';
import { SigninComponent } from './components/auth/signin/signin.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ObjViewerComponent } from './components/viewer/obj-viewer/obj-viewer.component';
import { ViewerMainComponent } from './components/viewer/viewer-main/viewer-main.component';
import { SetupProjectComponent } from './components/setup-project/setup-project.component';
import { UploadGeoslamComponent } from './components/setup-project/upload-geoslam/upload-geoslam.component';
import { ChangepwdComponent } from './components/auth/changepwd/changepwd.component';
import { SegmentedObjViewerComponent } from './components/viewer/segmented-obj-viewer/segmented-obj-viewer.component';
import { ProgressMainComponent } from './components/progress-main/progress-main.component';
import { TestComponent } from './components/test/test.component';
//import { AdminGuard } from './helpers/auth.guard';

const routes: Routes = [
  { path: '',   redirectTo: '/signin', pathMatch: 'full' },
  {path: 'signup', component: SignupComponent},
  {path: 'signin', component: SigninComponent},
  {path: 'overview', component: OverviewComponent},
  {path: 'forgotpwd', component: ForgotPasswordComponent},
  {path: 'changepwd', component: ChangepwdComponent},
  {path: 'obj-viewer', component: ObjViewerComponent},
  {path: 'viewer/:dat', component: ViewerMainComponent},
  {path: 'newproject/:orgName', component: SetupProjectComponent},
  {path: 'newproject/:orgName/:projectId', component: SetupProjectComponent},
  {path: 'upload/:orgName/:projectId', component: UploadGeoslamComponent},
  {path: 'segmented-viewer', component: SegmentedObjViewerComponent},
  {path: 'project-progress', component: ProgressMainComponent},
  {path: 'project-progress/:id', component: ProgressMainComponent},
  {path: 'test', component: TestComponent},
];
//canActivate: [AdminGuard]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
