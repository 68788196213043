import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CognitoService } from '../services/cognito.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: CognitoService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request);
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let token = await this.authService.getIdToken()
    let headers: any = {
      'Authorization': `Bearer ${token}`
    }
    if(!(req.body instanceof FormData)){
      headers['Content-Type']= 'application/json'
    }
    const authReq = req.clone({
      setHeaders: headers
    })
    return next.handle(authReq).toPromise()
  }
}
