/**
 * Zontro Scan-to-BIM FE_BE APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FileUpload { 
    projectId: string;
    fileId: string;
    file: Blob;
    tag: FileUpload.TagEnum;
    addedBy?: string;
    unitId?: string;
    areaId?: string;
    scanDate?: string;
}
export namespace FileUpload {
    export type TagEnum = 'mpp' | 'revit' | 'pano' | 'geoslam' | 'mapping' | 'other';
    export const TagEnum = {
        Mpp: 'mpp' as TagEnum,
        Revit: 'revit' as TagEnum,
        Pano: 'pano' as TagEnum,
        Geoslam: 'geoslam' as TagEnum,
        Mapping: 'mapping' as TagEnum,
        Other: 'other' as TagEnum
    };
}