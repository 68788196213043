import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CognitoService } from 'src/app/services/cognito.service';
import { UserService } from 'scantobim-openapi-frontend';
import { User } from 'src/app/models/user';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  cognitoUser: any
  //cognitoUser: User | undefined;
  email: string = ''
  givenName: string = ''
  familyName: string = ''
  isConfirm: boolean = false;
  alertMessage: string = '';
  showAlert: boolean = false;
  msg: boolean = false;
  password: string = ''
  password2: string = ''
  verify: boolean =false;
  errorMessage: string = '';
  code: string='';
  c1!: string; 
  c2!: string; 
  c3!: string; 
  c4!: string; 
  c5!: string; 
  c6!: string; 
  toVerify: any;
  showPassword: boolean = false
  constructor(private cognitoService: CognitoService, 
              private userService: UserService) { }

  ngOnInit() {
    this.cognitoUser = {} as User;
    this.isConfirm = false;
  }
  public async signUpWithCognito (form: NgForm) {
    if(this.password!=this.password2) {
      this.errorMessage = "Passwords don't match."
    } else {
      try {
        this.cognitoUser.password=this.password
        if(this.cognitoUser && this.cognitoUser.email && this.cognitoUser.password) {
          var a = await this.cognitoService.signUp(this.cognitoUser)
          // this.cognitoService.signUp(this.cognitoUser)
          .then(() => {
            this.isConfirm = true
            //console.log(a)
          })
          .catch((error: any) => {
            this.displayAlert(error.message)
          })
          if(!this.cognitoUser.userConfirmed) {
            this.verify = true; 
            this.errorMessage=''
            // this.userService.createUser(this.user).subscribe(
            //   result => {
            //     // console.log(result)  
            //   },error => {
            //     //this.displayMessage('Error adding user', 'danger')
            //     console.log(error)
            //   }
            // )
          }
          if (this.cognitoUser!==undefined) {
            return  
          } else {
              this.cognitoService.setLoggedIn(true)
              //this.router.navigate(['signup'])
          }
        } else {
          this.displayAlert("Missing email or password")
        }
      } catch(error:any){
          console.log(error)
          var er = error
          if(error.code=="InvalidParameterException") {
            this.errorMessage = 'Require atleast 1 number, special character, uppercase letter & lowercase letter with minimum password length of 6'
          } else if(error=="InvalidParameterException: Username should be an email."){
          this.errorMessage = 'Please enter a valid Email'
          } else if(error.code == "UsernameExistsException") {
            this.verify=true
          } else {this.errorMessage=error}
          // if(error='UsernameExistsException: An account with the given email already exists.') {
          //   this.verify = true
          // }
        }
    }
  }

  public confirmSignUp() {
    if(this.cognitoUser) {
      this.cognitoService.confirmSignUp(this.cognitoUser) 
      .then(() => {
        console.log("User created")
        //this.router.navigate(['/signin'])
      })
      .catch ((error: any) => {
        this.displayAlert(error.message);
      });
    } else {
      this.displayAlert("Missing user information")
    }
  }
  private displayAlert(message: string) {
    this.alertMessage = message;
    this.showAlert  = true
  }

  async onVerify() {
    this.code = this.c5.concat(this.c6.toString())
    this.code = this.c4.concat(this.code.toString())
    this.code = this.c3.concat(this.code.toString())
    this.code = this.c2.concat(this.code.toString())
    this.code = this.c1.concat(this.code.toString())
    try {
      await this.cognitoService.verify(this.cognitoUser.email, this.code)
      this.msg=true
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  }

  async onResendCode() {
    try {
      this.cognitoUser = await this.cognitoService.resendCode(this.email)
      this.toVerify=this.cognitoUser.CodeDeliveryDetails.Destination
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  }
  onVerifySuccess(){
    this.cognitoService.verifySuccess()
  }
  moveToNext(event: any) {
    const key = event.key; // const {key} = event; ES6+
    let next = event.target.nextElementSibling;
    let previous = event.target.previousElementSibling;
    if (key === "Backspace" || key === "Delete") {
        previous.focus()
    } else 
    
    
    if (next) {
      next.focus();
    } else {
      event.target.blur();
    }
  }

}
