import { Component, OnInit } from '@angular/core';
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Stats from 'three/examples/jsm/libs/stats.module'
@Component({
  selector: 'app-gltf-viewer',
  templateUrl: './gltf-viewer.component.html',
  styleUrls: ['./gltf-viewer.component.css']
})
export class GltfViewerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const scene = new THREE.Scene()
    scene.add(new THREE.AxesHelper(5))
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
  )
  camera.position.z = 2
  
  const renderer = new THREE.WebGLRenderer()
  renderer.useLegacyLights = false
  renderer.shadowMap.enabled = true
  renderer.setSize(window.innerWidth, window.innerHeight)
  document.body.appendChild(renderer.domElement)
  renderer.setClearColor( 0xdddddd ); 
  const controls = new OrbitControls(camera, renderer.domElement)
  controls.enableDamping = true 
  
  const loader = new GLTFLoader()
  loader.load(
      // '/assets/models/wall/scene.gltf',
    //   'assets/models/town_wall.glb',
      // 'assets/models/damaged_wall1.glb',
      'assets/models/gltf/wheel.glb',
      function (gltf) {
          gltf.scene.traverse(function (child) {
              if ((child as THREE.Mesh).isMesh) {
                  //const m = child as THREE.Mesh
                  //m.receiveShadow = true
                  //m.castShadow = true
              }
              if ((child as THREE.Light).isLight) {
                  const l = child as THREE.SpotLight
                  //l.castShadow = true
                  //l.shadow.bias = -0.003
                  //l.shadow.mapSize.width = 2048
                  //l.shadow.mapSize.height = 2048
              }
          })
          scene.add(gltf.scene)
          const box = new THREE.Box3().setFromObject( gltf.scene );
          const center = box.getCenter( new THREE.Vector3() );

          //gltf.scene.position.x += ( gltf.scene.position.x - center.x );
          //gltf.scene.position.y += ( gltf.scene.position.y - center.y );
          //gltf.scene.position.z += ( gltf.scene.position.z - center.z );
      },
      (xhr) => {
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      (error) => {
          console.log(error)
      }
  )
  
  window.addEventListener('resize', onWindowResize, false)
  
  function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()
      renderer.setSize(window.innerWidth, window.innerHeight)
      render()
  }
  
  const stats = new Stats()
  document.body.appendChild(stats.dom)
  
  function animate() {
      requestAnimationFrame(animate)
  
      controls.update()
  
      render()
  
      stats.update()
  }
  
  function render() {
      renderer.render(scene, camera)
  }
  
  animate()
  }

}
