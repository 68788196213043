<!-- <app-nav></app-nav> -->
<div class="container-fluid mt-3 pt-4" style="background-color: #4e92da; height: 98vh;" >
    <div class="card h-100 pl-4 " >
        <div class="card-body">
            <div class="container-fluid" >
                <div class="row" >
                    <div class="col container" style="width: 50%; padding-right: 0px;" >
                        <div class="card text-dark bg-light mb-3" style="width: 100%; height: 85vh; border-radius: 8px 0px 0px 8px; ">
                            <div class="card-header " style="border-color: #bbd7f5; ">
                                <div class="row">
                                    <div class="col">
                                        <a class="btn btn-outline-secondary btn-sm " style="text-align: left;"(click)="openModal()"><fa-icon [icon]="faArrowLeft"></fa-icon>  Project List</a>
                                    </div>
                                </div>
                            </div>
                            <ngb-accordion  #acc="ngbAccordion" (panelChange)="toggleAccordian($event)" [closeOthers]="true" style="width: 100%; height: 80vh; overflow-y: scroll !important;">
                                <ngb-panel class="max2" *ngFor="let item of projectList;index as i" [id] = i.toString() >
                                    <ng-template ngbPanelTitle >
                                        <!-- <div style="background-color: red;"> -->
                                            <p style="font-size: 20px; margin-bottom: 0px;"><span class="badge badge-mine ">{{i+1}}</span><span style="font-weight: 600; color: rgb(93, 97, 99);">&nbsp;{{item.projectName}}</span></p>
                           
                                        <!-- </div> -->
                                    </ng-template>
                              
                                    <ng-template ngbPanelContent >
                                        <div style="width: 100%; height: 62vh; overflow-y: scroll !important;">
                                            <p *ngIf="!fileList || fileList.length==0" class="text-muted" style="font-style: italic; ">No Files uploaded yet!</p>
                                            <a class=" card3 m-2" *ngFor="let fl of fileList" (click)="getFileProgress(fl)">
                                                <!-- <div class="card-body"> -->
                                                    <p>Scan Date: <span style="font-weight: 600;">{{fl.scanDate}}</span> <span class="text-muted" style="font-style: italic;"> &nbsp; ({{fl.processingStatus}})</span></p>
                                                    <p>Uploaded by: <span style="font-weight: 600;">{{fl.name}}</span> <span class="text-muted" style="font-style: italic;"> on {{fl.uploadDate.substring(0,16).replace("T"," ")}}</span></p>
                                                    <!-- <p>{{projectInfo.areaTitle1 | titlecase}}: {{fl.unitId}}</p>
                                                    <p>{{projectInfo.areaTitle2 | titlecase}}: {{fl.areaId}}</p>  -->
                                                    Filename: {{fl.fileName}}
                                                    <div class="go-corner" href="#">
                                                        <div class="go-arrow">
                                                          →
                                                        </div>
                                                    </div>
                                                <!-- </div> -->
                                                </a>

                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                    <div class="col container-fluid"  style="width: 50%; padding-left: 0px; ">
                        <div class="card text-dark bg-light mb-3" style="width: 100%; height: 85vh; border-radius: 0px 8px 8px 0px;">
                            <div class="card-body overflow-auto" *ngIf="showProgress" style="padding-left: 20px; margin-bottom: 20px; margin-top: 20px; font-family: 'Roboto', sans-serif;">
                                <div >
                                    <!-- ngif="selectedScan.length>0 && fileList.length>0" -->
                                     <div class="row">
                                        <div class="col">
                                            <h3 style="color: #303030; font-size: large; ">{{selectedProjectName}}</h3>
                                        </div>
                                        <div class="col">
                                            <div *ngIf = "currentStep==7"
                                            style=" float: right; font-size: 16px;"  >
                                                <a [href]=bimFilePath 
                                                    class="btn btn-sm btn-primary" 
                                                    style="margin-left: 20px;" 
                                                    target="_blank" 
                                                    [download]=bimFileName> Download BIM
                                                </a>
                                            </div>
                                        </div>
                                     </div>
                                    <br>
                                    <div class="row align-items-center" align="center" style="color: white;">
                                        <div class="col align-items-center" style="background-color: #bbd7f5; padding-top: 10px; width: max-content;">
                                            <p ><b>{{selectedScan.scanDate}}</b></p>
                                            <p class="text-muted"  style="font-size: small;">Scan Date</p>
                                        </div>
                                        <div class="col" style="background-color: #cecece; padding-top: 10px; margin-left: 2px;" >
                                            <p ><b>{{fileLastModifiedDate | titlecase}}</b></p>
                                            <p class="text-muted" style="font-size: small;">Last modified</p>
                                        </div>
                                        <div class="col" style="background-color: #a5ccf5; padding-top: 10px; margin-left: 2px;" *ngIf="fileLiveDataArr && fileLiveDataArr.length>0" >
                                            <!-- <p class="text-muted" >{{selectedScan.processingStatus}}</p> -->
                                            <p class="text-muted" >{{fileLiveDataArr[0].processingStatus}}</p>
                                            
                                            <p class="text-muted" style="font-size: small;">Status</p>
                                        </div>
                                        <div class="col" style="background-color: #c6d0db; padding-top: 10px; margin-left: 2px;" >
                                            <p class="text-muted" ><span style="font-size: small;">Unit: </span><b>{{selectedScan.unitId | titlecase}}</b></p>
                                            <p class="text-muted" ><span style="font-size: small;">Floor: </span><b>{{areaId | titlecase}}</b></p>
                                        </div>
                                    </div>
                                </div><br><br>
                                <div class="step-time d-flex justify-content-between" *ngIf="!smallScreen && fileLiveDataArr && fileLiveDataArr.length>0">
                                    <!-- <div class="step-text" *ngIf="currentStep>=2" style="color: #005b96;">{{selectedScan.step1ActTime}}</div><div class="step-text" *ngIf="currentStep<2">{{selectedScan.step1EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=3" style="color: #005b96;" >{{selectedScan.step2ActTime}}</div><div class="step-text" *ngIf="currentStep<3">{{selectedScan.step2EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=4" style="color: #005b96;">{{selectedScan.step3ActTime}}</div><div class="step-text" *ngIf="currentStep<4">{{selectedScan.step3EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=5" style="color: #005b96;">{{selectedScan.step4ActTime}}</div><div class="step-text" *ngIf="currentStep<5">{{selectedScan.step4EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=6" style="color: #005b96;">{{selectedScan.step5ActTime}}</div><div class="step-text" *ngIf="currentStep<6">{{selectedScan.step5EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=7" style="color: #005b96;">{{selectedScan.step6ActTime}}</div><div class="step-text" *ngIf="currentStep<7">{{selectedScan.step6EstTime}}</div> -->
                                    <div class="step-text" *ngIf="currentStep>=2" style="color: #005b96;">{{fileLiveDataArr[0].step1ActTime}}</div><div class="step-text" *ngIf="currentStep<2">{{fileLiveDataArr[0].step1EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=3" style="color: #005b96;" >{{fileLiveDataArr[0].step2ActTime}}</div><div class="step-text" *ngIf="currentStep<3">{{fileLiveDataArr[0].step2EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=4" style="color: #005b96;">{{fileLiveDataArr[0].step3ActTime}}</div><div class="step-text" *ngIf="currentStep<4">{{fileLiveDataArr[0].step3EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=5" style="color: #005b96;">{{fileLiveDataArr[0].step4ActTime}}</div><div class="step-text" *ngIf="currentStep<5">{{fileLiveDataArr[0].step4EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=6" style="color: #005b96;">{{fileLiveDataArr[0].step5ActTime}}</div><div class="step-text" *ngIf="currentStep<6">{{fileLiveDataArr[0].step5EstTime}}</div>
                                    <div class="step-text" *ngIf="currentStep>=7" style="color: #005b96;">{{fileLiveDataArr[0].step6ActTime}}</div><div class="step-text" *ngIf="currentStep<7">{{fileLiveDataArr[0].step6EstTime}}</div>
                                </div>
                                <div class="progress px-1" style="height: 3px; margin-left: 15px; margin-right: 15px;">
                                    <!-- <ngb-progressbar class="mb-3" type="primary" [value]="75" ></ngb-progressbar> -->
                                    <div class="progress-bar" role="progressbar" [style.width]="(selectedFileProgress/100)*100 + '%'"  aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div class="step-container d-flex justify-content-between" style="margin-left: 15px; margin-right: 15px;">
                                    <div class="step-circle" onclick="displayStep(1)" placement="top" ngbTooltip="File uploaded">1</div>
                                    <div class="step-circle" onclick="displayStep(2)" placement="top" ngbTooltip="Generating PCD">2</div>
                                    <div class="step-circle" onclick="displayStep(3)" placement="top" ngbTooltip="PCD ready">3</div>
                                    <div class="step-circle" onclick="displayStep(4)" placement="top" ngbTooltip="Segmentation started">4</div>
                                    <div class="step-circle" onclick="displayStep(5)" placement="top" ngbTooltip="Segmented PCD ready">5</div>
                                    <div class="step-circle" onclick="displayStep(6)" placement="top" ngbTooltip="Segmented elements">6</div>
                                    <div class="step-circle" onclick="displayStep(7)" placement="top" ngbTooltip="BIM ready">7</div>
                                </div>
                                <div class="step-container d-flex justify-content-between" *ngIf="!smallScreen">
                                    <div class="step-text" >File uploaded</div>
                                    <div class="step-text" >Generating PCD</div>
                                    <div class="step-text" >PCD ready</div>
                                    <div class="step-text" >Segmentation started</div>
                                    <div class="step-text" >Segmented PCD ready</div>
                                    <div class="step-text" >Segmented elements</div>
                                    <div class="step-text" >BIM ready</div>
                                </div>
                                <br>
                                <div class="row">
                                    <div  class="col" *ngIf="fileLiveDataArr && fileLiveDataArr.length>0">
                                        <!-- <p class="text-muted" >Remarks: {{selectedScan.remarks}}</p>
                                        <p class="text-muted" >Error: {{selectedScan.processingError}}</p> -->
                                        <p class="text-muted" >Remarks: {{fileLiveDataArr[0].remarks}}</p>
                                        <p class="text-muted" >Error: {{fileLiveDataArr[0].processingError}}</p>
                                    </div><br>
                                    <div class="col d-grid gap-2 d-md-flex justify-content-md-end">
                                        <div class="row">
                                        <!-- <button class="btn btn-sm btn-outline-primary mr-3 " *ngIf = "selectedScan.step>=2 && selectedScan.step<=6"
                                            style=" float: right; font-size: 16px;"  
                                            (click) = "openLiveProcessing()" >
                                            Live Processing <i class="fas fa-chevron-right"></i>
                                        </button> -->
                                            <button class="btn btn-sm btn-secondary mr-3 " *ngIf = "currentStep>=3"
                                                style=" float: right; font-size: 16px;"  
                                                (click) = "onViewScannedPCD(selectedScan)" >
                                                View Scanned PCD <i class="fas fa-chevron-right"></i>
                                            </button>
                                            <button class="btn btn-sm btn-secondary mr-3 mt-2" *ngIf = "currentStep>=5"
                                                style=" float: right; font-size: 16px;"  
                                                (click) = "onViewSegmentedPCD(selectedScan)" >
                                                View Segmented PCD <i class="fas fa-chevron-right"></i>
                                            </button>
                                            <!-- <button class="btn btn-sm btn-primary mr-3 mt-2" *ngIf = "currentStep==7"
                                                style=" float: right; font-size: 16px;"  
                                                (click) = "downloadBIM(selectedScan)" >
                                                Download BIM<i class="fas fa-chevron-right"></i>
                                            </button> -->

                                        </div>
                                    </div>
                                </div><br>
                                
                                <div class="row" *ngIf="cvLiveDataArr">
                                    <!-- <div class="row1">
                                        <p class="modal-title">Live processing sequence: File {{fileId}} ( {{scanDate}})</p>
                                        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
                                    </div> -->
                                    <div class="row2" style="background-color: black; color: white;">
                                        <div class="pt-2" *ngIf="cvLiveDataArr && cvLiveDataArr.length<1">No processing data found.</div>
                                        <div *ngFor="let item of cvLiveDataArr"></div>
                                        <table class="table"  *ngIf="cvLiveDataArr">
                                            <tbody *ngFor="let item of cvLiveDataArr" >
                                                    <tr>
                                                        <td style="width: 20%; background-color: rgb(0, 0, 0) !important; color: white !important; border: 0;">
                                                            {{item.statusTimestamp.substring(0,19).replace("T"," ")}}
                                                        </td>
                                                        <td style="background-color: rgb(0, 0, 0) !important; color: white !important; border: 0;">{{item.status}} </td>
                                                    </tr>            
                                            </tbody>
                                        </table>
                                    </div> 
                                </div>
                                <br>
                                <div class="justify-content-center">
                                    <button class="btn btn-sm btn-secondary " *ngIf="cvLiveDataArr && cvLiveDataArr.length>1"
                                        style=" float: middle; font-size: 16px;"  
                                        (click) = "onDownloadStatusList()" >
                                        Download <i class="fas fa-chevron-right"></i>
                                    </button>
                                    <!-- <button (click) = "stopPoll()">Stop</button>
                                    <a 
                                    [href]=bimFilePath 
                                    class="btn btn-default" 
                                    style="margin-left: 20px;" 
                                    target="_blank" 
                                    (click) = "openFile()"
                                    [download]=bimFileName>
                                        Download Import File Template
                                    </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>