
<body style='background-image:url("assets/images/Building3-1.jpg"); height: 100vh;  background-size: cover; background-repeat: no-repeat;'>
    <div class="wind text-left">
<!-- <body class="wind text-left"> -->
    <div class="d-flex aligns-items-center justify-content-center card w-75 mx-auto" style="padding-left: 5%; padding-top: 20px; padding-bottom: 50px;"> 
      <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
      <a style="color: #4E91DA; font-weight: 650;">    ZBIMS</a>   
    <div *ngIf="!currentUser"> 
      <p class="mt-5 mb-3 text-muted" style="font-size: 12px;"> </p>
      <main class="form-signin">
        <div class="input-group mb-3">
          <form (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm" novalidate>
            <div *ngIf="errorMessage" type="danger"><p style=" color: red;">{{ errorMessage }}</p></div>
            <h1 class="h3 mb-4 pt-10 fw-normal" style="color: #64b5f6;">Sign in </h1>
            <div class="form-floating mb-2">
              <input type="email" class="form-control " size="45" name= "email" id="floatingInput" [(ngModel)]="email" placeholder="name@example.com" required>
              <label for="floatingInput">Email address</label>
            </div>
            <div class="form-floating mt-2 mb-2">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control form-control-sm" name="password" id="floatingPassword" [(ngModel)]="password" placeholder="Password" required>
              <label for="floatingPassword">Password</label>
                <div>
                    <input type="checkbox" [checked]="showPassword" (change)="showPassword=!showPassword" name="givenName">
                Show Password
                </div>
            </div>

            <button class="w-20 btn btn-primary" type="submit">Sign in</button>
          </form>
        </div>
        <p class="mt-1 mb-1 text-muted" style="font-size: 12px;"> <a [routerLink]="['/forgotpwd']"> Forgot Password?</a></p>
      </main>
      <p class="mt-2 mb-3 text-muted" style="font-size: 12px;"> Don't have an account?  <a [routerLink]="['/signup']"> Create an Account</a></p>
    </div> 
    <div  *ngIf="currentUser" class="signin">
      <main class="form-signin">
        <!-- <h1 class="h3 mb-5 fw-normal">Sign in to Zontro</h1> -->
          <div class="row mt-4">
            <h5 class="h5 mb-3 fw-normal">Logged in as {{currentUser.attributes.email}}<p *ngIf="userInDB && !clientVerification" style=" color: rgb(0, 153, 255);">Access Level: {{ accessLevel | uppercase }}</p></h5>
          </div>
          <div class="form-floating mb-2"  *ngIf="clientVerification" style="width: 325px;">
            <select class="form-select" aria-label="select" style="width: 100%;" (change)="checkClient($event)">
              <option *ngFor="let client of clientList" 
                 >
                  {{client.clientName}}
              </option>
            </select>
          </div>
          <div class="form-floating mb-2" *ngIf="clientVerification" style="width: 325px;">
            <input type="number" class="form-control " name= "client" id="floatingClient" [(ngModel)]="clientCode" placeholder="12345678"  required>
            <label for="floatingClient">Company Code</label> <span class="mt-5 mb-3 text-muted" style="font-size: 12px;">Ignore if you don't have admin code and request for access. </span>
          </div>
          <div class="row">
          <div class="form-floating mt-2 mb-2">
            <div *ngIf="errorMessage" type="danger"><p style=" color: red;">{{ errorMessage }}</p></div>
            <div class="btn-group" role="group" aria-label="First group">
              <!-- <button class="w-20 btn btn-primary mr-3"  type="submit" (click)="onProceed()">Proceed to UI</button> -->
              <button *ngIf="clientVerification"  class="w-20 btn btn-primary mr-3"  type="submit" (click)="onCompanyAdminAccess()">Verify admin access</button>
              <button *ngIf="clientVerification"  class="w-20 btn btn-primary mr-3"  type="submit" (click)="onCompanyRequest()">Skip</button>
              <button *ngIf="!clientVerification"   class="w-20 btn btn-primary mr-3"  type="submit" (click)="onOverview()">Overview</button>
              <button class="w-20 btn btn-primary mr-3" type="submit" (click)="onSignOut()">Sign out</button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <!-- <p class="mt-5 mb-1 text-muted" style="font-size: 12px;">&copy; <a href="#"> Terms and Conditions</a> | <a href="#"> Privacy Policy</a></p>      -->
    </div>
    </div>
  </body>