import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AppComponent } from './app.component';
import { SigninComponent } from './components/auth/signin/signin.component';
import { SignupComponent } from './components/auth/signup/signup.component';
import { MessageModalComponent } from './components/auth/message-modal/message-modal.component';
import { BASE_PATH } from 'scantobim-openapi-frontend';
import { environment } from 'src/environments/environment';
import { Amplify, Auth } from "aws-amplify";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OverviewComponent } from './components/overview/overview.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { ObjViewerComponent } from './components/viewer/obj-viewer/obj-viewer.component';
import { ViewerMainComponent } from './components/viewer/viewer-main/viewer-main.component';
import { NavComponent } from './components/shared/nav/nav.component';
import { GltfViewerComponent } from './components/viewer/gltf-viewer/gltf-viewer.component';
import { SetupProjectComponent } from './components/setup-project/setup-project.component';
import { UploadGeoslamComponent } from './components/setup-project/upload-geoslam/upload-geoslam.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ModalConfirmComponent } from './shared/modal-confirm/modal-confirm.component';
import { ModalReadyComponent } from './components/shared/modal-ready/modal-ready.component';
import { ModalCollabComponent } from './components/shared/modal-collab/modal-collab.component';
import { PcdViewerComponent } from './components/viewer/pcd-viewer/pcd-viewer.component';
import { ChangepwdComponent } from './components/auth/changepwd/changepwd.component';
import { SegmentedObjViewerComponent } from './components/viewer/segmented-obj-viewer/segmented-obj-viewer.component';
import { PcdOverlapComponent } from './components/viewer/segmented-obj-viewer/pcd-overlap/pcd-overlap.component';
import { ModalProgressComponent } from './components/shared/modal-progress/modal-progress.component';
import { ProgressMainComponent } from './components/progress-main/progress-main.component';
import { TestComponent } from './components/test/test.component';
import { ModalRapidStatusComponent } from './components/modal-rapid-status/modal-rapid-status.component';

Amplify.configure({
  Auth: {
      region: environment.cognito.awsRegion,
      userPoolId: environment.cognito.userPoolId,
      userPoolWebClientId: environment.cognito.userPoolWebClientId,
      mandatorySignIn: true,
      authenticationFlowType: environment.cognito.authenticationFlowType,
  }
});
@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    MessageModalComponent,
    OverviewComponent,
    ForgotPasswordComponent,
    ObjViewerComponent,
    ViewerMainComponent,
    NavComponent,
    GltfViewerComponent,
    SetupProjectComponent,
    UploadGeoslamComponent,
    ModalConfirmComponent,
    ModalReadyComponent,
    ModalCollabComponent,
    PcdViewerComponent,
    ChangepwdComponent,
    SegmentedObjViewerComponent,
    PcdOverlapComponent,
    ModalProgressComponent,
    ProgressMainComponent,
    TestComponent,
    ModalRapidStatusComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    FontAwesomeModule
  ],
  providers: [{
    provide: BASE_PATH, useValue: environment.basePath,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
