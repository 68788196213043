import { Component, OnInit } from '@angular/core';
import { UploadService } from 'scantobim-openapi-frontend';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  fileName: string = '';
  tag: string = '';
  unsupportedFile: any;
  isSaving: boolean = false;
  fileId:  string = 'file1';
  selectedFile: any;
  fileProcessed: boolean = false;
  message: string = '';

  constructor(private uploadService: UploadService,) { }

  ngOnInit() {
  }
  async processFile(setupFiles: any){
    let file: File = setupFiles.files[0];
    this.selectedFile = undefined
    this.message = ''
    const reader = new FileReader();
    var length = 10;
    if(file.name) {
      var truncatedName = file.name.substring(0,Math.min(length,file.name.length));
      this.fileName = truncatedName+'...'
      var [filename, fileExtension] = file.name.split(/\.(?=[^\.]+$)/);
      fileExtension = fileExtension.toLowerCase()
      this.tag='geoslam'
      this.unsupportedFile = false
      var fileTypesAllowed = ["zip",]
      if(fileTypesAllowed.includes(fileExtension)==false)  {
        this.unsupportedFile = true
        //this.displayMessage(`File formats allowed: .zip`, 'danger')
      }
      if(this.unsupportedFile == false) {
        this.isSaving = true
        this.fileId = filename.replace(/ /g,"_");
        reader.addEventListener('load', (event: any) => {
          this.selectedFile = {
            src: event.target.result,
            file: file
          }
          this.fileProcessed = true
          this.isSaving = false 
        })
        reader.readAsDataURL(file);
      } else {}
    }
  }
  displayMessage(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }
  onSave(){
    // if(this.selectedFile ){
    //   this.isSaving = true
    //   this.fileProcessed = false   
    //   var sub3 = this.uploadService.addFilesForm("projectABC", 
    //     this.fileId.replace(/ /g,"_"), 
    //     this.selectedFile.file, 
    //     this.tag, 
    //     "userabc", 
    //     "unitabc", 
    //     "areaabc",
    //     "2024-04-18"
    //     ).subscribe((v:any) => {
    //       if(v){
    //         this.isSaving = false

    //         console.log("isSaving=",this.isSaving)
    //       }
    //       }
    //     );
    //   //this.subscription.push(sub3)
    //  } else if(!this.selectedFile || this.selectedFile!=undefined) {
    //   this.message = "No file uploaded, please retry."
    //  }
  }
  uploadChunking() {
    //const fileInput = document.getElementById('file-input');
    this.selectedFile.file.addEventListener('change', async (event: any) => {
      const file = event.target.files[0];
      const chunkSize = 1024 * 1024; // 1MB
      const totalChunks = Math.ceil(file.size / chunkSize);
      let startByte = 0;
      for (let i = 1; i <= totalChunks; i++) {
        const endByte = Math.min(startByte + chunkSize, file.size);
        const chunk = file.slice(startByte, endByte);
        await uploadChunk(chunk, totalChunks, i);
        startByte = endByte;
      }
      console.log('Upload complete');
    });
    async function uploadChunk(chunk: any, totalChunks: any, currentChunk: any) {
      const formData = new FormData();
      formData.append('file', chunk);
      formData.append('totalChunks', totalChunks);
      formData.append('currentChunk', currentChunk);
      const response = await fetch('/upload/chunk', {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        throw new Error('Chunk upload failed');
      }
    }
  }
}
