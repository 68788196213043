import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from 'scantobim-openapi-frontend';
import { CognitoService } from 'src/app/services/cognito.service';
//import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
declare var jQuery:any
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  currentUser: any
  userInitials: any
  orgName: any
  //active = 1;
  currentPage: any;
  displayMenu: boolean = true;
  // constructor (private titleService:Title, private activatedRoute: Route) { 
  //   this.currentPage = this.activatedRoute;
  //   console.log(this.currentPage)
  //   if (this.currentPage === '/project-progress') { this.displayMenu = false; }
  //   this.titleService.setTitle("ZBIMS");
  //  }
  constructor(  private authService: CognitoService,
                private userService: UserService,
                private router: Router  ) {
                  router.events.subscribe((value) => {
                    if(value instanceof NavigationEnd)  {
                      var currentPage = this.getUrlWithoutParams()
                      if (currentPage === '/viewer') { 
                        this.displayMenu = false; 
                      } else this.displayMenu = true; 
                    }
                  });
                }
                

  async ngOnInit() {
    try{
      this.currentUser = await this.authService.getCurrentUser()
      var initials = []
      var name = this.currentUser.attributes.given_name+" "+this.currentUser.attributes.family_name
      initials = name.trim().split(" ");
      this.userInitials=""
      if(initials.length>1) {
        this.userInitials = this.userInitials.concat(initials[0].substring(0,1),initials[initials.length-1].substring(0,1))
      } else {
        this.userInitials = this.userInitials.concat(initials[0].substring(0,1))
      }

      this.getProjects()
    } catch (error:any){
      console.log(error) 
      if(error=="The user is not authenticated") {
        this.router.navigate(['signin'])
      }
    }
    
  }
  signout() {
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  getUrlWithoutParams(){
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}; 
    urlTree.fragment = null; // optional
    return urlTree.toString().split("/s")[0];
 }
  getProjects() {
    this.userService.getUserDetails(this.currentUser.username).subscribe(
      (data: any) => {  
        if(!jQuery.isEmptyObject(data)) {
          this.orgName = data[0].orgName
        }
      }
    )
  }
}
