import { Component, OnInit, inject, TemplateRef, ViewEncapsulation } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule, NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { ModalProgressComponent } from '../../shared/modal-progress/modal-progress.component'

@Component({
  selector: 'app-segmented-obj-viewer',
  templateUrl: './segmented-obj-viewer.component.html',
  styleUrls: ['./segmented-obj-viewer.component.css']
})
export class SegmentedObjViewerComponent implements OnInit {
  faArrowLeft = faArrowLeft;
  faRefresh = faRefresh
  faInfoCircle = faInfoCircle
  faCube = faCube
  viewSide: number = 0
  sectionSelected: number = 0
  progressPercent: any;
  modalRef: any
  constructor( private _NgbModal: NgbModal ) { }

  ngOnInit() {
   this.openModal()
  }
  rightView() {
    this.viewSide = 3
  }
  leftView() {
    this.viewSide = 2
  }
  topView() {
    this.viewSide = 1
  }
  selectSec1() {
    this.sectionSelected = 1
    this.openModal()
  }
  selectSec2() {
    this.sectionSelected = 2
    this.openModal()
  }
  selectSec3() {
    this.sectionSelected = 3
    this.openModal()
  }
  // openVerticallyCentered(content: TemplateRef<any>) {
	// 	this.modalService.open(content, { centered: true });
	// }
  openModal() {
    this.modalRef = this._NgbModal.open(ModalProgressComponent, { centered: true, backdrop : 'static', keyboard : false});
    // modalRef.componentInstance.user = this.progressPercent;
    // modalRef.result.then((result) => {
    //   if (result) {
    //     //this.pageClose=result
    //   }
    // })
  }
  loadProgressPercent(ev: number) {
    this.progressPercent = Math.round(ev)
    this.modalRef.componentInstance.progressPercent = this.progressPercent;
  }
  closeModal(ev: number) {
    if(ev==1) {
      this._NgbModal.dismissAll("completed")
    }
  }
 }

