import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-ready',
  templateUrl: './modal-ready.component.html',
  styleUrls: ['./modal-ready.component.css']
})
export class ModalReadyComponent implements OnInit {
  @Output() goingBack: EventEmitter<any> = new EventEmitter<any>()

  constructor(    private _NgbActiveModal: NgbActiveModal,
                  private router: Router) { }

  ngOnInit() {
    this.goingBack.emit(false)
  }
  get activeModal() {
    return this._NgbActiveModal;
  }
  confirmMove() {
    this.activeModal.close(1);
    // this.goingBack.emit(true)
    // this.activeModal.close()
  }
}
