import { Injectable } from '@angular/core';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth'

@Injectable({
  providedIn: 'root'
})
export class CognitoService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  constructor(private router: Router) {
    Amplify.configure ({
      Auth:environment.cognito
    })
  }
  setLoggedIn(value: boolean){
    this.loggedIn.next(value)
  }
  getCurrentUser():Promise<CognitoUser | any> {
    return new Promise((resolve, reject) => { 
      Auth.currentAuthenticatedUser()
        .then( user1 => {
          if(user1){
            resolve(user1)
          }
        })
        .catch((err:any) => {
          this.setLoggedIn(false)
          //console.log(err)
          reject(err)
        })
    })
  }
  signUp(user: any): Promise<CognitoUser | any>{
    return new Promise((resolve, reject) => {
      Auth.signUp({
        username: user.email, 
        password: user.password,
        attributes: {
          'given_name': user.givenName,
          'family_name': user.familyName 
        }
      })
        .then(user => {
          if(user){                            
            resolve(user);
            if(user.userConfirmed==false) {
              this.router.navigate(['signup'])
            }
          }
        })
        .catch(err => {
          this.setLoggedIn(false)
          console.log(err)
          reject(err)
        });
    })
  }
  signIn(email: string, password: string): Promise<CognitoUser | any>{
    return new Promise((resolve, reject) => {
      try {
        Auth.signIn(email, password)
        .then(user => {
          if(user){                            
            resolve(user);
            window.location.reload();
          }
        })
        .catch(err => {
          this.setLoggedIn(false)
          console.log(err)
          const code = err.code;
          if(code=='UserNotConfirmedException') {
            this.router.navigate(['signup'])
          }
          reject(err)
        });
      } catch(er: any) {
        console.log(er)
      }

    })
  }
  signOut() {
    Auth.signOut({ global: true })
        .then(data => {
          this.setLoggedIn(false)
          localStorage.removeItem('statusType')
          this.router.navigate(['signin'])
          .then(() => {
            window.location.reload();
          });
        })
        .catch(err => console.log(err));
  }

  public confirmSignUp(user:User):Promise<any> {
    return Auth.confirmSignUp(user.email,user.code);
  }
  verify(email:string, code: string){
    return new Promise((resolve, reject) => {
      Auth.confirmSignUp(email, code)
      .then(data => {
        //console.log(data)
        resolve(data);
        return (data)
        //this.router.navigate(['signin'])
      })
      .catch(err => {
        this.setLoggedIn(false)
        console.log(err)
        reject(err)
      })
    })
  }

  resendCode(email:string){
    return new Promise((resolve, reject) => {
      Auth.resendSignUp(email)
      .then(data => {
        //console.log(data)
        resolve(data);
      })
      .catch(err => {
        this.setLoggedIn(false)
        console.log(err)
        reject(err)
      })
    })
  }

  verifySuccess() {
    this.router.navigate(['signin'])
  }

  sendCode(email:string):Promise<any>{
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
      .then(data => {
        //console.log(data)
        resolve(data);
      })
      .catch(err => {
        this.setLoggedIn(false)
        console.log(err)
        reject(err)
      })
    })
  }
  resetPwd(email: string, code: string, password: string): Promise<any>{
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(email, code, password)
      .then(data => {
        //console.log(data)
        resolve(data);
      })
      .catch(err => {
        this.setLoggedIn(false)
        console.log(err)
        reject(err)
      })
    })
  }

  getIdToken(): Promise<string>{
    return new Promise((resolve, reject) => {
      Auth.currentSession()
          .then(session => {
            if(session) resolve(session.getIdToken().getJwtToken())
          })
          .catch(err => {
            console.log(err)
            if(err=='No current user') {
              this.router.navigate(['signin'])
            }
            reject(err)
          });
    });
  }
  getUser():Promise<any>{
    return new Promise((resolve, reject) => {
      Auth.currentUserInfo()
      .then(data => {
        //console.log(data)
        resolve(data);
      })
      .catch(err => {
        this.setLoggedIn(false)
        console.log(err)
        reject(err)
      })
    })
  }
}
