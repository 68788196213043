<p>test works!</p>
<p  class="file-selector"  
    style=" height:30px; color: cornflowerblue;" >
    <a onclick="document.getElementById('fileUpld').click()">  Upload File </a>
</p>
<input  type="file"    
id="fileUpld" 
name="fileUpld"
accept=".zip"
#fileUpld 
(change)="processFile(fileUpld)" 
style="display:none" />   
<button class="btn btn-primary" type="button" (click)="onSave()">Upload</button>