/**
 * Zontro Scan-to-BIM FE_BE APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface User { 
    userId?: string;
    name: string;
    email: string;
    clientId?: string;
    orgName?: string;
    isAdmin?: number;
    isEditor?: number;
}