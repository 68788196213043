<!-- <ng-template #content2 let-modal> -->
<div class="modal-body">
    <div style="text-align: center;"><h4 >Collaborators for all Project </h4></div>
    <br>
    {{userList}}
    <table class="table" *ngIf="userList">
        <tbody *ngFor="let item of userList">
                <tr>
                    <td>
                        {{item.name}}
                    </td>
                    <td>{{item.email}} </td>
                    <td style="text-align: center;">
                        <button *ngIf="!item.admin && !item.editor && !item.newEditor" class="btn btn-outline-info btn-sm " type="button" (click) = pushEditor(item)>Add</button>
                        <button *ngIf="!item.admin && !item.editor && item.newEditor" class="btn btn-outline-info btn-sm " type="button" (click) = popEditor(item)>Remove</button>
                        <div *ngIf="item.admin" style="color: green;"><fa-icon [icon]="faCheckDouble"></fa-icon>&nbsp;Admin</div>
                        <div *ngIf="item.editor" style="color: green;">Editor</div>
                    </td>
                </tr>            
        </tbody>
    </table>
    <div class="text-muted" *ngIf="!userList">No {{clientId | titlecase}} users found</div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end" *ngIf="!userAccessModified">
        <button class="btn btn-outline-secondary btn-sm me-md-2" type="button" (click)="activeModal.close('Close click')">Cancel</button>
        <button class="btn btn-outline-secondary btn-sm " type="button" (click)=addCollab() >Confirm</button>
    </div>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end" *ngIf="userAccessModified">
        <button class="btn btn-outline-secondary btn-sm" type="button" (click)="activeModal.close('Close click')">Done</button>
    </div>
</div>
