<!-- <app-nav></app-nav> -->
<div class="container-fluid mt-3 pt-4" style="background-color: #4e92da; height: max-content;" >
    <div class="card h-100 pl-4 " >
        <div class="card-body">
            <div class="container-fluid" >
                <div class="row" *ngIf="!noAccessToProjects">
                    <div class="row" style="margin-left: 30px !important; margin-right: 30px !important; ">
                        <div class="col pt-4" >
                            <p style="font-size: 22px;">Projects</p>
                        </div>
                        <div class="col-md-4 p-4" >
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end" *ngIf="userIsAdmin">
                                <button class="btn btn-sm btn-secondary" (click)="editCollab()">Edit Collaborators </button>
                                <button class="nav-item nav-link-edit w-20 btn btn-sm btn-secondary mr-3 "  [routerLink]="['../newproject',clientId]">Create Project</button >
                            </div>
                        </div>
                    </div>
                    <!-- <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul class="navbar-nav justify-content-center" style="padding-left: 50%!important;" >
                          <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
                            <li [ngbNavItem]="1" [destroyOnHide]="false">
                              <a ngbNavLink> My Projects</a>
                              <ng-template ngbNavContent>
                                sadafaf
                              </ng-template>
                            </li>
                            <li [ngbNavItem]="2">
                              <a ngbNavLink>Accessible Projects</a>
                              <ng-template ngbNavContent>
sdfsfssdv
                              </ng-template>
                            </li>
                          </ul>
                        </ul>
                      </div>
                      <div class="container-fluid">
                        <div class="row">
                          <div [ngbNavOutlet]="nav" ></div>
                        </div>
                      </div> -->
                    
                    <div class="row-md-4 ml-3 mr-3 " style="margin-bottom: 5px; margin-top: .2%;" *ngFor="let item of projectList;index as i">
                        <div class="card m-3 shadow p-1 mb-1 bg-body rounded ">
                            <div class="card-header" style="font-size: 15px;">
                                <p style="font-size: 20px; margin-bottom: 0px;"><span class="badge badge-mine ">{{i+1}}</span><span style="font-weight: 600; color: rgb(93, 97, 99);">&nbsp;{{item.projectName}}</span></p>
                                    <div class="btn-group position-absolute top-0 end-0" *ngIf="userIsAdmin" style="color: rgb(83, 83, 83);" role="group" aria-label="Basic example">

                                         <!--For Project based collaboration, to be used in later stage of project-->
                                        <!-- <div ngbDropdown class="d-inline-block">
                                            <button type="button" class="btn btn-outline-secondary" id="dropdownBasic1" ngbDropdownToggle><fa-icon [icon]="faDotMenu"></fa-icon></button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <button class="btn btn-sm" ngbDropdownItem (click)="editCollab()">Edit Collaborators </button>
                                                <button class="btn btn-sm" ngbDropdownItem (click)="editProject()">Edit Project</button>
                                                <hr>
                                                <button class="btn btn-sm" ngbDropdownItem (click)="deleteProject(item)">Delete Project</button>
                                            </div>
                                        </div> -->
                                    </div>
                                <p style="font-size: 14px; color: rgb(104, 108, 110); margin: 0px; margin-left: 35px;">Created: {{item.creationDate.substring(0,10)}}</p>
                                <p style="font-size: 14px; color: rgb(104, 108, 110); margin-left: 35px;">Last Modified: {{item.lastModifiedDate.substring(0,10)}}</p>
                            </div>
                            <div class="card-body p-2 m-2" >
                                <div class="row">
                                    <div class="col" style="font-weight: 500">
                                        <p class="text-muted" style="font-size: 15px; margin: 0px;">Organization: <span style="font-weight: 600; color: black;">{{item.companyName}}</span></p>
                                        <p class="text-muted" style="font-size: 15px; margin: 0px;">Admin: <span style="font-weight: 600; color: black;">{{item.projectOwner}}</span></p>
                                        <!-- <p class="text-muted" style="font-size: 15px; margin: 0px;">Status: <span style="font-weight: 600; color: black;">{{item.status}}</span></p> -->
                                        <p class="text-muted" style="font-size: 15px; margin: 0px;">Details: <span style="font-weight: 600; color: black;">{{item.projectAddress}} </span></p>
                                        <!-- <p class="text-muted" style="font-size: 15px; margin: 0px;">Scans: <span style="font-weight: 600; color: black;">Processing: {{item.processingCount}}, Processed: {{item.processedCount}} </span></p> -->
                                    </div>
                                    <div class="col" *ngIf="userIsAdmin || userIsEditor">
                                        <div class="row">
                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <button class="btn btn-sm btn-outline-primary mr-3 " *ngIf = "item.status==='In Progress' || item.status==='Setup files uploaded'"
                                                    style=" float: right; font-size: 16px;"  
                                                    (click) = "onViewScannedPCD(item)" >
                                                    View Scanned PCD <i class="fas fa-chevron-right"></i>
                                                </button>
                                                <button class="btn btn-sm btn-outline-primary mr-3 " *ngIf = "item.status==='In Progress' || item.status==='Setup files uploaded'"
                                                style=" float: right; font-size: 16px;"  
                                                (click) = "onViewSegmentedPCD(item)" >
                                                View Segmented PCD <i class="fas fa-chevron-right"></i>
                                            </button>
                                            </div>
                                        </div>
                                        <div class="row  pt-2">
                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <button class="btn btn-sm btn-outline-primary mr-3 " 
                                                    style=" float: right; font-size: 16px;"  
                                                    (click) = "viewFiles(item)" >
                                                    Upload files <i class="fas fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <!-- <div class="row pt-2">
                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <button class="btn btn-sm btn-outline-primary mr-3 " 
                                                        style=" float: right; font-size: 16px;" 
                                                        (click) = "onSelectedProgress(item,i)"  
                                                        >
                                                        Check Progress <i class="fas fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>  -->
                                        <!-- <div class="row  pt-2">
                                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <button class="btn btn-sm btn-outline-primary mr-3 " 
                                                    style=" float: right; font-size: 16px;"  
                                                    (click) = "deleteProject(item)" >
                                                    Delete Project <i class="fas fa-chevron-right"></i>
                                                </button>
                                            </div>
                                        </div>                        -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="noAccessToProjects">
                    <div class="col" style="width: 10%; ">
                    </div>
                    <div class="col" style="width: 50%;">
                        <div class="d-flex aligns-items-center justify-content-center card w-75 mx-auto" style="margin-top:10%; "></div>
                        <div class="card text-dark bg-light mb-3 text-center" style="width: 100%; border: 2px #4E91DA ;">
                            <div class="card-header" style="border-color: #bbd7f5;">
                            {{orgName}}
                            </div>
                            <div class="card-body">
                                <h5 class="card-title">New project</h5>
                                <p class="card-text">Setup a new project for {{orgName}}.</p><br><br><br>
                                <a href="#" class="btn btn-primary" [routerLink]="['../newproject',clientId]">Create Project</a>
                            </div>
                            <div class="card-footer text-muted">
                            Project admin: {{currentUser.attributes.given_name}} {{this.currentUser.attributes.family_name}}
                            </div>
                        </div>
                    </div>
                    <div class="col" style="width: 10%;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

