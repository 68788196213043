import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
//import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { MapControls } from 'three/examples/jsm/controls/MapControls.js';
import Stats from 'three/examples/jsm/libs/stats.module'
import { ActivatedRoute, Router } from '@angular/router';
//import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
//import { ArcballControls } from 'three/examples/jsm/controls/ArcballControls.js';
import {  NgbModal  } from '@ng-bootstrap/ng-bootstrap';
import { ModalProgressComponent } from '../../shared/modal-progress/modal-progress.component'

@Component({
  selector: 'app-obj-viewer',
  templateUrl: './obj-viewer.component.html',
  styleUrls: ['./obj-viewer.component.css']
})
export class ObjViewerComponent implements OnInit {
  @Input() resetView: any
  @Input() pageClose: any
  @Input() pcdFileInfo: any;
  @Input() viewSide: any
  @Output() resetViewFlip = new EventEmitter<any>()
  objloader = new OBJLoader();
  container: any
  camera: any 
  scene: any 
  renderer:any
  cube: any
	object: any
  controls: any
  gui: any
  folderOptions: any 
  folderAnimations: any
  fileType: any
  center!: THREE.Vector3;
  viewAngle: number = 0;

  step: any;
  progressPercent: any;
  modalRef: any
  //resetViewFlip: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute,private _NgbModal: NgbModal) { }

  ngOnInit() {
    // const routeParams = this.route.snapshot.paramMap
    // const dataParam = routeParams.get('dat');
    // // this.fileType= "scanned" //fileType
    // this.pcdFileInfo = dataParam
    // let step =JSON.parse(this.pcdFileInfo)[0].stage
    // this.step = step
    const scene = new THREE.Scene()
    scene.add(new THREE.AxesHelper(5))
    THREE.Object3D.DEFAULT_UP.set(1, 0, 1);
    this.openModal()
    const light = new THREE.PointLight(0xffffff, 1000)
    light.position.set(2.5, 7.5, 15)
    scene.add(light)

    const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
    )
    camera.position.z = 3

    const renderer = new THREE.WebGLRenderer({
      // canvas: document.querySelector('#bg1')
    })
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(innerWidth,innerHeight)
    // renderer.setSize( 1500, 800 );
    document.body.appendChild(renderer.domElement)

    const controls = new OrbitControls(camera, renderer.domElement)
    // const controls = new MapControls( camera, renderer.domElement );
    // controls.enableDamping = true
    // controls.dampingFactor = 0.05;
    // controls.screenSpacePanning = false;
    // controls.minDistance = 100;
		// controls.maxDistance = 500;
    // controls.maxPolarAngle = Math.PI / 2;

    const objLoader = new OBJLoader()
    var fileName = ''
    // console.log(JSON.parse(this.pcdFileInfo))
    let filePath=JSON.parse(this.pcdFileInfo)[0].pcdFilePath
    //let basePCDPath = "C:/Users/deban/dev/ScanToBIM_UI_23/"
    let basePCDPath = "/assets/"
    if(this.step==="stage_3") {
      fileName = basePCDPath+filePath
      //fileName = "https://storage.googleapis.com/as-bucket1234112/room_181.obj"
      // console.log(fileName)
      //fileName = '/assets/models/obj/area_b1_n.obj'
      // fileName = '/assets/models/obj/sample_h_6_n.obj'
    } else if(this.step==="stage_5") {
      // fileName = '/assets/models/obj/sample_h_6.obj'
      // fileName = '/assets/models/obj/area_1_b1.obj'
      fileName = basePCDPath+filePath
      
    } else {
      this.router.navigate(['/overview'])
    }
    var minPan = new THREE.Vector3( - 50, - 50, - 50 );
    var maxPan = new THREE.Vector3( 50, 50, 50 );
    var _v = new THREE.Vector3();
    objLoader.load(
       fileName,
        (object) => {
            scene.add(object)
            const box = new THREE.Box3().setFromObject( object );
            const center = box.getCenter( new THREE.Vector3() );
            object.position.x += ( object.position.x - center.x );
            object.position.y += ( object.position.y - center.y );
            object.position.z += ( object.position.z - center.z );
            console.log(center)
            // camera.zoom = -6;
            // camera.updateProjectionMatrix();
           camera.position.set(20,0,90)
           //object.rotation.z = 90 * Math.PI/180;
           // object.rotation.x = -90 * Math.PI/180;
           this.scene=scene
           this.renderer=renderer
           this.object = object
           this.camera=camera
           this.center = center
           this.scene.lookAt(0,0,90)
           this.closeModal(1)
        //    const gui = new GUI();
				// gui.add( controls, 'zoomToCursor' );
				// gui.add( controls, 'screenSpacePanning' );
        },
        (xhr) => {
           // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
            this.loadProgressPercent(((xhr.loaded / xhr.total)*100))

        },
        (error) => {
            console.log(error)
        }
    )
    
    window.addEventListener('resize', onWindowResize, false)
    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
        renderer.setSize(window.innerWidth, window.innerHeight)
        render()
    }
    const stats = new Stats()
    document.body.appendChild(stats.dom)

    const animate = () => {
        requestAnimationFrame(animate)

        
        controls.autoRotate
        controls.autoRotateSpeed
        // controls.keys = {
        //   LEFT: 'ArrowLeft', //left arrow
        //   UP: 'ArrowUp', // up arrow
        //   RIGHT: 'ArrowRight', // right arrow
        //   BOTTOM: 'ArrowDown' // down arrow
        //}
        controls.mouseButtons = {
          LEFT: THREE.MOUSE.ROTATE,
          MIDDLE: THREE.MOUSE.DOLLY,
          RIGHT: THREE.MOUSE.PAN
        }
        
        controls.update() 
        controls.addEventListener("change", () => {
          _v.copy(controls.target);
          controls.target.clamp(minPan, maxPan);
          _v.sub(controls.target);
          camera.position.sub(_v);
          this.controls=controls
      })
        render()

        stats.update()
    }

    function render() {
        renderer.render(scene, camera)
    }

    animate()
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['pcdFileInfo']!==undefined && this.pcdFileInfo){
      let newdata = changes['pcdFileInfo'].currentValue;
      this.pcdFileInfo=newdata
      if (this.pcdFileInfo) {
        this.step =JSON.parse(this.pcdFileInfo)[0].stage
      }
      
    }
    if(changes['resetView']!==undefined && this.resetView){
      let newdata = changes['resetView'].currentValue;
      this.resetView=newdata
      if (this.resetView) {
        this.controls.reset();
        this.camera.position.set(200,0,40)
        this.resetViewFlip.emit(true)
      } else if(!this.resetView) {
        this.resetViewFlip.emit(false)
      }
    }
    if(changes['pageClose']!==undefined && this.pageClose){
      let newdata = changes['pageClose'].currentValue;
      this.pageClose=newdata
      if(this.pageClose==1) {
        if(this.scene) {
          this.scene.remove(this.object);
        }
        if(this.renderer) {
          this.renderer.dispose();
          this.renderer.domElement.remove()
        }

        this.router.navigate(['/project-progress'])
      }
    }
    if(changes['viewSide']!==undefined && this.viewSide){
      let newdata = changes['viewSide'].currentValue;
      this.viewSide=newdata
      this.viewAngle = newdata
      if(this.viewAngle==2) {
        this.camera.position.set(0,40,0)
      } else if(this.viewAngle==3) {
        this.camera.position.set(0,0,40)
      } else if(this.viewAngle==1) {
        this.camera.position.set(40,0,0)
      }
    }
  }
  openModal() {
    this.modalRef = this._NgbModal.open(ModalProgressComponent, { centered: true, backdrop : 'static', keyboard : false});
  }
  loadProgressPercent(ev: number) {
    this.progressPercent = Math.round(ev)
    this.modalRef.componentInstance.progressPercent = this.progressPercent;
  }
  closeModal(ev: number) {
    if(ev==1) {
      this._NgbModal.dismissAll("completed")
    }
  }
  ngOnDestroy() {
    if(this.scene && this.renderer) {
      this.scene.remove(this.object);
      this.renderer.dispose();
      this.renderer.domElement.remove()
    }
  }
}
