<!-- <app-nav></app-nav> -->

<div class="container-fluid mt-4 pt-4" >
    <div class="row pt-3" >
      <div class="col">
          <a class="btn btn-outline-secondary btn-sm " (click)="openModal()"><fa-icon [icon]="faArrowLeft"></fa-icon>  Exit PCD Viewer</a>
      </div>
      <div class="col ml-0 justify-content-center" style=" margin-left: -12%;"   ><span style="font-weight: bold;">{{projectId | titlecase}}</span>&nbsp;(Scanned on {{scanDate}})</div>
      <!-- <div class="col ml-0 d-md-flex justify-content-end" *ngIf = "showLegend && stage==='stage_5'">
        <table  class="table text-center mr-0" style="width: 500px;" >
          <tbody style="font-size: 13px;">
              <tr >
                <td style="background-color: #ff0000;"><span style="color: white;">Beam</span></td>
                <td style="background-color: #00ff00;">Column</td>
                <td style="background-color: #0000ff;"><span style="color: white;">Door</span> </td>
                <td style="background-color: #ffff00;">Floor</td>
                <td style="background-color: #00ffff;">Roof</td>
                <td style="background-color: #808080;"><span style="color: white;">Wall</span></td>
                <td style="background-color: #ff00ff;">Window</td>
                <td style="background-color: #000000;"><span style="color: white;">Stairs</span></td>
                <td style="background-color: #c86432;"><span style="color: white;">Clutter</span></td>
              </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-1 ml-0" style="width: 50px;" *ngIf="stage==='stage_5'">
        <div class=" d-md-flex justify-content-md-end ml-0">
          <button class="btn btn-sm btn-1 btn-primary" type="button"  (click) = "toggleLegend()">&nbsp;&nbsp;</button>
        </div>
      </div> -->
    </div>
    <div class="row objRegView pt-1">
        <!-- <nav class="navbar navbar-expand-lg  navbar-light bg-light  shadow fixed-top" style="height: 3% !important;">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                <ul class="navbar-nav justify-content-center" style="padding-left: 50%!important;" >
                  <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
                    <li [ngbNavItem]="1" [destroyOnHide]="false">
                      <a ngbNavLink> &nbsp;&nbsp; Uploaded Point Cloud &nbsp;&nbsp; </a>
                      <ng-template ngbNavContent>
                        
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink> &nbsp;&nbsp; Segmented Point Cloud &nbsp;&nbsp; </a>
                      <ng-template ngbNavContent>
                        
                      </ng-template>
                    </li>
                  </ul>
                </ul>
              </div>
        </nav> -->
        <!-- <app-gltf-viewer></app-gltf-viewer> -->
        <app-obj-viewer style="background-color: blue;" [resetView]="reset" [pageClose]="pageClose" [pcdFileInfo]="pcdFileInfo" [viewSide] = 'viewSide' (resetViewFlip) = "pcdViewResetDone($event)"></app-obj-viewer>
        <!-- <app-segmented-obj-viewer></app-segmented-obj-viewer> -->
        <!-- <div class="container-fluid">
            <div class="row">
            <div [ngbNavOutlet]="nav" ></div>
            </div>
        </div> -->
    </div>
</div>
<div class="controls">
    <!-- <div class="row">
        <button class="btn btn-primary btn-sm" ><fa-icon [icon]="faInfoCircle"></fa-icon></button>
    </div> -->
    <!-- <button>pan</button>
    <button>rotate</button>
    <button>zoom</button> -->
    <!-- <div class="row pt-2">
      <div class="col">
        <button style="width: 40px; height: 40px;" class="btn btn-primary" (click)="leftView()" ><fa-icon [icon]="faCube"></fa-icon></button>
      </div>
      <div class="col">
        <button  class="btn btn-primary" style="transform: rotate(90deg); width: 40px; height: 40px;" (click)="topView()" ><fa-icon [icon]="faCube"  style="transform: rotate(90deg);"></fa-icon></button>
      </div>
      <div class="col">
        <button  class="btn btn-primary"  style="transform: rotate(180deg); width: 40px; height: 40px;" (click)="rightView()"><fa-icon [icon]="faCube"></fa-icon></button>
      </div>
      <div class="col">
        <button class="btn btn-primary" (click)="resetView()"><fa-icon [icon]="faRefresh">Refresh</fa-icon></button>
      </div>
    </div> -->
  <div class="col ">
    <div class="row pt-2">
      <button class="btn btn-outline-primary"  (click)="leftView()" ><fa-icon [icon]="faEye"></fa-icon>&nbsp; Side</button>
    </div>
    <div class="row pt-2" >
      <button  class="btn btn-outline-primary" (click)="topView()" ><fa-icon [icon]="faEye"></fa-icon>&nbsp; Front</button>
    </div>
    <div class="row pt-2">
      <button  class="btn btn-outline-primary"  (click)="rightView()"><fa-icon [icon]="faEye"></fa-icon>&nbsp; Top</button>
    </div>
    <div class="row pt-2">
      <button class="btn btn-outline-primary" (click)="resetView()">Reset</button>
    </div>
  </div>
  <div class="row-sm-1 pt-2" style="width: 50px;" *ngIf="stage==='stage_5'">
    <div class=" d-md-flex justify-content-md-end ml-0">
      <button class="btn btn-sm btn-1 btn-primary" style="width: 100px;" type="button"  (click) = "toggleLegend()">&nbsp;&nbsp;</button>
    </div>
  </div>
  <div class="row pt-2 justify-content-end" *ngIf = "showLegend && stage==='stage_5'">
    <!-- <table  class="table text-center mr-0"  >
      <tbody style="font-size: 13px; width: max-width;">
          <td >
            <tr style="background-color: #ff0000;"><span style="color: white;">Beam</span></tr>
            <tr style="background-color: #00ff00;">Column</tr>
            <tr style="background-color: #0000ff;"><span style="color: white;">Door</span> </tr>
            <tr style="background-color: #ffff00;">Floor</tr>
            <tr style="background-color: #00ffff;">Roof</tr>
            <tr style="background-color: #808080;"><span style="color: white;">Wall</span></tr>
            <tr style="background-color: #ff00ff;">Window</tr>
            <tr style="background-color: #000000;"><span style="color: white;">Stairs</span></tr>
            <tr style="background-color: #c86432;"><span style="color: white;">Clutter</span></tr>
          </td>
      </tbody>
    </table> -->
    <ul class="Legend" style="color: white;">
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #ff0000;">
        </span>
        <span class="Legend-label">
          Beam
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #00ff00;">
        </span>
        <span class="Legend-label">
          Column
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #0000ff;">
        </span>
        <span class="Legend-label">
          Door
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #ffff00;">
        </span>
        <span class="Legend-label">
          Floor
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #00ffff;">
        </span>
        <span class="Legend-label">
          Roof
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #ffffff;">
        </span>
        <span class="Legend-label">
          Wall
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #000000;">
        </span>
        <span class="Legend-label">
          Stairs
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #ff00ff;">
        </span>
        <span class="Legend-label">
          Window
        </span>
      </li>
      <li class="Legend-item">
        <span class="Legend-colorBox" style="background-color: #c86432;">
        </span>
        <span class="Legend-label">
          Clutter
        </span>
      </li>
    </ul>
  </div>

  <!-- <button>pan</button>
  <button>rotate</button>
  <button>zoom</button> -->
  <div class="row pt-2 justify-content-end">
      
  </div>
</div>

