import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-progress',
  templateUrl: './modal-progress.component.html',
  styleUrls: ['./modal-progress.component.css']
})
export class ModalProgressComponent implements OnInit {
  @Input() progressPercent: any
  @Input() closeProgOverlay: any
  constructor(private _NgbActiveModal: NgbActiveModal) { }

  ngOnInit() {
  }
  get activeModal() {
    return this._NgbActiveModal;
  }
}
