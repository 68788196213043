import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, Subscription, switchMap, takeUntil, timer } from 'rxjs';
import { UploadService } from 'scantobim-openapi-frontend';
@Component({
  selector: 'app-modal-rapid-status',
  templateUrl: './modal-rapid-status.component.html',
  styleUrls: ['./modal-rapid-status.component.css']
})
export class ModalRapidStatusComponent implements OnInit {

  subscription1!: Subscription
  @Input() public projectId: any;
  @Input() public fileId: any;
  @Input() public scanDate: any;
  beDataList: any = []
  subscription: Subscription = new Subscription()
  stopPolling = new Subject()
  cvLiveMsg$!: any
  cvLiveMsg: any
    cvLiveDataArr: any[] = []
  constructor(private _NgbActiveModal: NgbActiveModal, private uploadService: UploadService) { }

  ngOnInit(): void {
  this.pollLiveProcessingData()
  }
  get activeModal() {
    return this._NgbActiveModal;
  }
  pollLiveProcessingData() {
    console.log(this.projectId, this.fileId, this.scanDate)
    let req = {
      projectId:this.projectId,
      fileId: this.fileId, 
      scanDate: this.scanDate
    }
    // this.cvLiveMsg =this.uploadService.getProcessingStatusRapid(req).subscribe(
    //   result=> {
    //   console.log(result)
    //   }
    // )
    
    this.cvLiveMsg$ = timer(0, 500).pipe(
      switchMap(_ => this.uploadService.getProcessingStatusRapid(req)),
      takeUntil(this.stopPolling)
    )
    let subscription3 = this.cvLiveMsg$.subscribe( {
      next: (result: any[]) => {
        if(result && result.length>0) {
          this.cvLiveDataArr = result
        } else {this.cvLiveDataArr = []}
      },
      error: (e: any) => console.log(e)
    })
    this.subscription.add(subscription3)

    // this.beDataList = []
    // if(this.subscription1) this.subscription1.unsubscribe()
    // this.subscription1 = this.uploadService.getProcessingStatusRapid(this.projectId, this.fileId, this.scanData).subscribe( {
    //   next: (v) => { 
    //     this.beDataList = v
    //   }
    // })
  }
  ngOnDestroy(){
    this.stopPolling.next('')
    if(this.subscription){
      this.subscription.unsubscribe()
    }
  }
}
