<app-nav></app-nav>
<div class="container-fluid mt-3 pt-4" style="background-color: #4e92da; height: 98vh;">
    <div class="card h-100 pl-4 " style="background-color: rgb(233, 231, 228);">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row"  >
                    <div class="col container" style="width: 50%; padding-right: 0px;" >
                        <div class="card text-dark bg-light mb-3" style="width: 100%; height: 85vh; border-radius: 8px 0px 0px 8px;">
                            <div class="card-header " style="border-color: #bbd7f5; ">
                                <div class="row">
                                    <div class="col">
                                        <a class="btn btn-outline-secondary btn-sm " style="text-align: left;"(click)="openModal()"><fa-icon [icon]="faArrowLeft"></fa-icon>  Project List</a>
                                    </div>
                                    <div class="col" style="text-align: center;">
                                        <span class="justify-content-center">Upload files for {{orgName | uppercase}}</span>
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                            <div class="card-body">
                                <!-- <h5 class="card-title text-center">Upload files </h5> -->
                                <div  style="font-size: 15px;" *ngIf="projectInfo">
                                    <!-- <div class="row">
                                        <div class="col"> -->
                                            <p style="font-size: 20px; margin-bottom: 0px;"><span style="font-weight: 600; color: rgb(93, 97, 99);">&nbsp;Project: {{projectInfo.projectName}}</span></p>
                                            <p style="font-size: 15px; color: rgb(104, 108, 110); margin: 0px; margin-left: 5px;">{{projectInfo.projectAddress}}</p>
                                            <p style="font-size: 15px; color: rgb(104, 108, 110); margin: 0px; margin-left: 5px;">Created on: {{projectInfo.creationDate.substring(0,10)}}</p>
                                            <p style="font-size: 15px; color: rgb(104, 108, 110); margin: 0px; margin-left: 5px;">Created by: {{projectInfo.projectOwner}}</p>
                                        <!-- </div>
                                        <div class="col">
                                         
                                        </div>
                                    </div>                               -->
                                    <br>
                                    <!-- <p style="font-size: 14px; color: rgb(104, 108, 110); margin-left: 35px;">Status: {{projectInfo.status}}</p> -->
                                    <div class="form-floating mb-2 " >
                                        <textarea class="form-control"  size="45" rows="3" [(ngModel)]="fileRemarks" ></textarea>
                                        <label for="projectName">Remarks/Details</label>
                                    </div> 
                                    <!-- <p class="text-muted" style="font-style: italic; font-size: 15px;">Remarks/Details about the scan</p> -->
                                </div>
                                
                                <!-- <div class="input-group" style="padding-bottom: 5px;"> -->
                                <div class="row" *ngIf="projectInfo && projectUnits">
                                    <div class="col">
                                        <div class="form-floating mb-2 " >
                                            <div id="calendar ngb-dp-today">
                                                <form class="row row-cols-sm-auto">
                                                    <div class="col-12">
                                                        <div class="input-group">
                                                            <span class="input-group-text">Scan Date</span>
                                                            <input class="form-control" 
                                                            placeholder="yyyy-mm-dd"
                                                            name="dp" 
                                                            [readonly]="true"
                                                            [(ngModel)]="model" 
                                                            ngbDatepicker #d="ngbDatepicker"
                                                            (ngModelChange)="changeDate()">
                                                            <!-- [minDate]="minDate" 
                                                            [maxDate]="maxDate" -->
                                                            <button class="btn btn-outline-secondary " (click)="d.toggle()" type="button" style="background-color:white">
                                                                <fa-icon [icon]="faCalendar"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <br>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row" *ngIf="projectInfo && projectUnits">
                                    <div class="col">
                                        <div >
                                            <div class="input-group">
                                                <span class="input-group-text">{{projectInfo.areaTitle1| titlecase}}</span>
                                                <select class="form-select" aria-label="unit select"  id="unit_count" (change)="fileUnit($event.target)">
                                                    <option *ngFor="let title of projectUnits" [value]="title.unitId">
                                                        {{title.unitName}}
                                                    </option>   
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div >
                                            <div class="input-group" *ngIf="unitAreas">
                                                <span class="input-group-text">{{projectInfo.areaTitle2| titlecase}}</span>
                                                <select class="form-select" aria-label="area select"  id="area" (change)="fileArea($event.target)">
                                                    <option *ngFor="let title1 of unitAreas" [value]="title1.areaId">
                                                        {{title1.areaName}}
                                                    </option>   
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>   -->
                                <br>    
                                <div class="file" >
                                    <div class="row" >
                                        <div class="col text-left" >
                                            <p  class="file-selector"  
                                                style=" height:30px; color: rgb(17, 83, 204); cursor: pointer; border: 1px dotted; border-radius: 5px; width: 130px; padding-top: 2px;margin-left: 10px;" >
                                                <a onclick="document.getElementById('fileUpld').click()"> &nbsp;&nbsp; <fa-icon [icon]="faUpload"></fa-icon>&nbsp; Select File </a>
                                            </p>
                                            <input  type="file"    
                                                    id="fileUpld" 
                                                    name="fileUpld"
                                                    accept=".zip"
                                                    #fileUpld 
                                                    [disabled]="uploadDisabled"
                                                    (change)="processFile(fileUpld)" 
                                                    style="display:none" />   
                                        </div>                                                
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                                            <p>{{fileName}}</p>
                                        </div>
                                        <div class="col-sm-3" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                                            <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <button class="btn btn-sm btn-primary" style="margin-left: 10px; background-color: rgb(17, 83, 204); width: 130px" type="button" (click)="onSave()" [disabled]="uploadDisabled">Upload to Server</button> <p class="text-muted" style="font-style: italic; font-size: 15px; margin-left: 10px">File size allowed upto 1GB</p>
                                </div> 
                                <div *ngIf="message" class="alert alert-danger" role="alert">
                                    {{ message }}
                                </div>
                                <div *ngIf="successMessage" class="alert alert-success" role="alert">
                                    {{ successMessage }}
                                </div>
                            </div>
                            <div class="card-footer text-muted text-center" *ngIf="currentUser">
                            {{currentUser.attributes.given_name}} {{currentUser.attributes.family_name}}
                            </div>
                        </div>
                    </div>
                                                                <!-- <div class="row" *ngIf="isSaving || fileProcessed">
                                                <div
                                                class="progress-bar progress-bar-info"
                                                role="progressbar"
                                                attr.aria-valuenow="{{ progress }}"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                [ngStyle]="{ width: progress + '%' }"
                                                >
                                                {{ progress }}%
                                            </div> -->
                    <div class="col container-fluid"  style="width: 50%; padding-left: 0px; ">
                        <div class="card text-dark bg-light mb-3" style="width: 100%; height: 85vh; border-radius: 0px 8px 8px 0px;">
                            <div class="card-body overflow-auto" style="padding-left: 20px; margin-bottom: 20px;">
                                <p *ngIf="!fileList || fileList.length==0" class="text-muted" style="font-style: italic; ">No Files uploaded yet!</p>
                                <div class="card m-2" *ngFor="let fl of fileList">
                                    <div class="card-body">
                                        <p>Scan Date: <span style="font-weight: 600;">{{fl.scanDate}}</span> <span class="text-muted" style="font-style: italic;"> &nbsp; ({{fl.processingStatus}})</span></p>
                                        <p>Uploaded by: <span style="font-weight: 600;">{{fl.name}}</span> <span class="text-muted" style="font-style: italic;"> on {{fl.uploadDate.substring(0,16).replace("T"," ")}}</span></p>
                                        <!-- <p>{{projectInfo.areaTitle1 | titlecase}}: {{fl.unitId}}</p>
                                        <p>{{projectInfo.areaTitle2 | titlecase}}: {{fl.areaId}}</p>  -->
                                        <p>Details: {{fl.remarks}}</p>
                                        Filename: {{fl.fileName}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col"  *ngIf="projectConfirmed" style="width: 10%; ">
                        <h2 *ngIf="!fileList">No files uploaded yet!</h2>
                    

                     -->
                    </div>
                </div>
            </div>
        </div>

<!-- </div> -->







<!-- <div class="row">
    <div class="col-8">
      <label class="btn btn-default p-0">
        <input type="file" (change)="selectFile($event)" />
      </label>
    </div>
  
    <div class="col-4">
      <button
        class="btn btn-success btn-sm"
        [disabled]="!selectedFiles"
        (click)="upload()"
      >
        Upload
      </button>
    </div>
  </div>
  
  <div *ngIf="currentFile" class="progress my-3">

  </div>
  
  <div *ngIf="message1" class="alert alert-secondary" role="alert">
    {{ message1 }}
  </div>
  
  <div class="card mt-3">
    <div class="card-header">List of Files</div>
    <ul class="list-group list-group-flush">
      <li *ngFor="let file of fileInfos | async" class="list-group-item">
        <a href="{{ file.url }}">{{ file.name }}</a>
      </li>
    </ul>
  </div> -->
  