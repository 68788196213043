import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService, UserService } from 'scantobim-openapi-frontend';
import { CognitoService } from 'src/app/services/cognito.service';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ModalCollabComponent } from '../shared/modal-collab/modal-collab.component';
declare var jQuery:any
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  faTrash = faTrashAlt
  faDotMenu = faEllipsisV
  currentUser: any;
  projectList: any
  orgName: any;
  clientId: any;
  noAccessToProjects: boolean = false
  active:any = '1'
  userIsAdmin: boolean = false;
  userIsEditor: boolean = false;
  subscription: any = []

  constructor(private authService: CognitoService, 
    private projectService: ProjectService, 
    private userService: UserService,
    private router: Router,
    private _NgbModal: NgbModal) { }

  async ngOnInit() {
    try {
      this.currentUser = await this.authService.getCurrentUser()
      // var initials = []
      // var name = this.currentUser.attributes.given_name+" "+this.currentUser.attributes.family_name
      // initials = name.trim().split(" ");
      // this.userInitials=""
      // if(initials.length>1) {
      //   this.userInitials = this.userInitials.concat(initials[0].substring(0,1),initials[initials.length-1].substring(0,1))
      // } else {
      //   this.userInitials = this.userInitials.concat(initials[0].substring(0,1))
      // }
      this.getProjects()
    } catch(error:any){
      console.log(error) 
      if(error=="The user is not authenticated") {
        this.router.navigate(['signin'])
      }
    }
  }
  signout() {
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  onViewScannedPCD(data: any) {
    //this.router.navigate(['ui',data.clientId,data.projectId]) 
    this.router.navigate(['viewer',"scanned"])  
  }
  onViewSegmentedPCD(data: any) {
    //this.router.navigate(['ui',data.clientId,data.projectId]) 
    this.router.navigate(['viewer',"segmented"])  
  }
  onSelectedProgress(data: any, i: number) {
      this.router.navigate(['project-progress', {id:i}])
  }
  getProjects() {
    var sub1 = this.userService.getUserDetails(this.currentUser.username).subscribe(
      (data: any) => {  
        if(!jQuery.isEmptyObject(data)) {
          this.orgName = data[0].orgName
          this.clientId = data[0].clientId
          if(data[0].isAdmin==1) {
            this.userIsAdmin=true
          } else if(data[0].isEditor==1) {
            this.userIsEditor=true
          }
          var sub3 = this.projectService.getProjects(data[0].clientId).subscribe(
            result=> {
              if(!result || result.length==0) {
                this.noAccessToProjects = true
              } else {
                this.noAccessToProjects = false
                this.projectList = result
              }
            }
          )
          this.subscription.push(sub3)
        }
        if(data.length==0) {
          this.router.navigate(['signin'])
        }
      }
    )
    this.subscription.push(sub1)
  }
  deleteProject(data: any){
    var delProject = {
      projectId: this.clientId+"/"+data.projectId,
      removedBy: this.currentUser.username,
    }
    var sub2 = this.projectService.deleteProject(delProject).subscribe((result: any) => { 
      this.getProjects()
    })
    this.subscription.push(sub2)

  }
  viewFiles(data: any) {
    this.router.navigate(['upload', data.clientId, data.projectId])
  }
  editCollab() {
    const modalRef = this._NgbModal.open(ModalCollabComponent, {backdrop : 'static', keyboard : false})
    modalRef.componentInstance.clientId = this.clientId;
  }
  editProject() {
    
  }
  ngOnDestroy() {
    if(this.subscription) {
      for(var i=0;i<this.subscription.length; i++) {
        this.subscription[i].unsubscribe()
      }
    }
  }
}
