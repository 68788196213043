/**
 * Zontro Scan-to-BIM FE_BE APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Organization } from '../model/organization';
import { User } from '../model/user';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { UserOrgAccess } from 'scantobim-openapi-frontend/model/userOrgAccess';


@Injectable({providedIn: 'root'})
export class UserService {

    protected basePath = 'http://localhost:3201/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create new User
     * 
     * @param body Created user object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(body: User, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public createUser(body: User, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public createUser(body: User, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public createUser(body: User, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/user/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns details of all clients
     * Operation to get clients
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrg(observe?: 'body', reportProgress?: boolean): Observable<Array<Organization>>;
    public getOrg(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Organization>>>;
    public getOrg(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Organization>>>;
    public getOrg(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Organization>>('get',`${this.basePath}/user/getorg`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns details by user
     * Operation to get user details
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetails(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getUserDetails(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getUserDetails(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getUserDetails(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getUserDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/user/get/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns details of all users
     * Operation to get users
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsers(observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getUsers(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getUsers(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getUsers(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/user/get`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Logs out current logged in user session
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public logoutUser(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public logoutUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public logoutUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public logoutUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/user/logout`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update details for existing user
     * 
     * @param body Updated user object
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserEditor(body: Array<UserOrgAccess>, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateUserEditor(body: Array<UserOrgAccess>, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateUserEditor(body: Array<UserOrgAccess>, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateUserEditor(body: Array<UserOrgAccess>, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateUserEditor.');
        }

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling updateUserEditor.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/user/update/${encodeURIComponent(String(orgId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns details by user
     * Operation to get user details
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserByOrg(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<User>>;
    public getUserByOrg(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<User>>>;
    public getUserByOrg(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<User>>>;
    public getUserByOrg(orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orgId === null || orgId === undefined) {
            throw new Error('Required parameter clientId was null or undefined when calling getUserByOrg.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<User>>('get',`${this.basePath}/user/getbyorg/${encodeURIComponent(String(orgId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
   /**
     * Update details for existing user
     * 
     * @param body Updated user object
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
   public updateUserAccessByOrg(body: Array<UserOrgAccess>, orgId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
   public updateUserAccessByOrg(body:  Array<UserOrgAccess>, orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
   public updateUserAccessByOrg(body:  Array<UserOrgAccess>, orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
   public updateUserAccessByOrg(body:  Array<UserOrgAccess>, orgId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

       if (body === null || body === undefined) {
           throw new Error('Required parameter body was null or undefined when calling updateUserAccess.');
       }
       if (orgId === null || orgId === undefined) {
        throw new Error('Required parameter clientId was null or undefined when calling updateUserAccessByOrg.');
    }

       let headers = this.defaultHeaders;

       // to determine the Accept header
       let httpHeaderAccepts: string[] = [
           'application/json'
       ];
       const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
       if (httpHeaderAcceptSelected != undefined) {
           headers = headers.set('Accept', httpHeaderAcceptSelected);
       }

       // to determine the Content-Type header
       const consumes: string[] = [
           'application/json'
       ];
       const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
       if (httpContentTypeSelected != undefined) {
           headers = headers.set('Content-Type', httpContentTypeSelected);
       }
       //console.log(body)
       return this.httpClient.request<ApiResponse>('post',`${this.basePath}/user/updateaccess/${encodeURIComponent(String(orgId))}`,
           {
               body: body,
               withCredentials: this.configuration.withCredentials,
               headers: headers,
               observe: observe,
               reportProgress: reportProgress
           }
       );
   }

    
}
