<app-nav></app-nav>
<div class="container-fluid mt-3 pt-4" style="background-color: #4e92da; height: 98vh;">
    <div class="card h-100 pl-4 " style="background-color: rgb(233, 231, 228);">
        <div class="card-body">
            <div class="container-fluid">
                <div class="row" >
                    <div class="col" style="width: 10%; ">
                    </div>
                    <div class="col container" style="width: 50%; " *ngIf="step!=5">
                        <div class="d-flex aligns-items-center justify-content-center card w-75 mx-auto" style="margin-top:10%; "></div>
                        <div class="card text-dark bg-light mb-3 text-center" style="width: 100%; height: 65vh; border: 2px #4E91DA ;">
                            <div class="card-header " style="border-color: #bbd7f5; ">
                                <div class="row">
                                    <div class="col">
                                        <a class="btn btn-outline-secondary btn-sm " (click)="openModal()"><fa-icon [icon]="faArrowLeft"></fa-icon>  Project List</a>
                                    </div>
                                    <div class="col" style="text-align: center;">
                                        <span class="justify-content-center">{{orgName}}</span>
                                    </div>
                                    <div class="col"></div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div *ngIf="message"  style="color: red;">{{message}}</div>
                                <form id="msform" >
                                    <fieldset *ngIf = "step==1 && !projectConfirmed">
                                        <h5 class="card-title">New project</h5>
                                        <p class="card-text">Setup a new project for {{orgName}}</p><br><br><br>
                                        <div class="form-floating mb-2 " >
                                            <input type="email" class="form-control " size="45" #projName name= "projectName" id="projectName" [(ngModel)]="projectName" (paste)="restrict();" (keypress)="restrict();" (keyup)="restrict();"  required>
                                            <label for="projectName">Project Name</label>
                                        </div> 
                                        <p class="text-muted" style="font-style: italic; font-size: smaller;">Please enter a project name to proceed..</p>
                                        <br><br>
                                        <a class="btn btn-primary text-center justify-content-end" (click) = "addProject()">Next</a>
                                        <br> 
                                    </fieldset>
                                    <fieldset *ngIf = "step==2 && projectConfirmed && !unitCountSelected">
                                        <h5 class="card-title" style="color: rgb(59, 151, 255);"> <span class="text-muted">Project:</span> {{projectName}}</h5>
                                        <br><br>
                                        <div class="form-floating mb-2 " >
                                            <div id="calendar">
                                                <form class="row row-cols-sm-auto">
                                                    <div class="col-12">
                                                        <div class="input-group">
                                                            <input class="form-control" 
                                                               placeholder="yyyy-mm-dd"
                                                               name="dp" 
                                                               [(ngModel)]="model" 
                                                               ngbDatepicker #d="ngbDatepicker"
                                                               (ngModelChange)="changeDate($event)">
                                                            <button class="btn btn-outline-secondary " (click)="d.toggle()" type="button" style="background-color:white">
                                                                <i class="fas fa-calendar-alt fa-fw"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <p class="text-muted" style="font-style: italic; font-size: smaller; text-align: left;">Project Start Date </p>
                                            <br>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div class="input-group" style="padding-bottom: 5px;">
                                                    <span class="input-group-text">1st level</span>
                                                    <select class="form-select" aria-label="unit select"  id="unitTitle" style="max-width: max-content;"  [(ngModel)]="unitTitle" name="unitTitle">
                                                        <option value="unit" selected>Unit</option>  
                                                        <option value="block" >Block</option> 
                                                        <option value="level" >Level</option>
                                                        <option value="area" >Area</option>  
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="input-group" style="padding-bottom: 5px;">
                                                    <span class="input-group-text">2nd level</span>
                                                    <select class="form-select" aria-label="area select"  id="areaTitle" style="max-width: max-content;"  [(ngModel)]="areaTitle" name="areaTitle">
                                                        <option value="level" selected>Level</option>  
                                                        <option value="floor" >Floor</option> 
                                                        <option value="area" >Area</option>
                                                        <option value="zone" >Zone</option>  
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="text-muted" style="font-style: italic; font-size: smaller; text-align: left;">
                                            Taxonomy of hierarchy</p>
                                        <div class="form-floating mb-2 "  >
                                            <br>
                                            <div class="input-group" style="padding-bottom: 5px;">
                                                <span class="input-group-text">1st level count</span>
                                                <select class="form-select" aria-label="unit select" (change)="unitSelected($event.target)" id="unit_count">
                                                    <option selected disabled> </option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>
                                        </div>
                                        <p class="text-muted" style="font-style: italic; font-size: smaller; text-align: left;">
                                            Number of units/blocks/levels. [This will be used later for uploading scans] </p>
                                        <br> <br>
                                        <a class="btn btn-primary text-center align-self-end" (click) = "addUnits()">Next</a> 
                                    </fieldset>
                                    <fieldset *ngIf = "step==3 && unitCountSelected">
                                        <h5 class="card-title" style="color: rgb(59, 151, 255); padding-bottom: 8px;" > <span class="text-muted">Project:</span> {{projectName}}</h5>
                                        <p class="text-muted" style="font-style: italic; font-size: smaller; text-align: left;">Add a name to the {{unitTitle}} and select {{areaTitle}} count for each</p>
                                        <form id="unitfields">
                                            <div class="input-group" *ngFor="let d of inputFieldArray" style="padding-bottom: 5px;">
                                                <span class="input-group-text">{{d.placeholder}}</span>
                                                <input type="text" class="form-control" aria-label="With textarea"
                                                        [(ngModel)]="d.unitName"
                                                        name="{{d.name}}">
                                                <select class="form-select" style="max-width: max-content;"  [(ngModel)]="d.areaCount" name="areaCount" >  
                                                    <option *ngFor="let area of unitAreaArr" value={{area}} >
                                                    {{area}}
                                                    </option>
                                                </select>
                                            </div>
                                            <a class="btn btn-primary text-center align-self-end" (click) = "confirmUnitNames()">Next</a> 
                                        </form>                              
                                    </fieldset>
                                    <fieldset *ngIf = "step==4 && unitNamesAdded">
                                        <h5 class="card-title" style="color: rgb(59, 151, 255); padding-bottom: 8px;" > <span class="text-muted">Project:</span> {{projectName}}</h5>
                                        <p class="text-muted" style="font-style: italic; font-size: smaller; text-align: left;">Add a name to the {{unitTitle}} and select {{areaTitle}} count for each</p>
                                        <form id="areafields">
                                            <div class="input-group">
                                                <span class="input-group-text">{{unitTitle| titlecase}}</span>
                                                <select class="form-select" aria-label="unit select"  id="unit_count" (change)="changeUnitinAreaInput($event.target)">
                                                    <option *ngFor="let title of inputFieldArray" [value]="title.unitName">
                                                        {{title.unitName}}
                                                    </option>   
                                                </select>
                                            </div>
                                            <br>
                                            <div class="input-group" *ngFor="let d of hierarchyArr[unitSelectNum].area" style="padding-bottom: 5px;">
                                                <span class="input-group-text">{{d.placeholder | titlecase}}</span>
                                                <input type="text" class="form-control" aria-label="With textarea"
                                                        [(ngModel)]="d.areaName"
                                                        name="{{d.id}}">
                                            </div>
                                            <!-- <div class="input-group" *ngFor="let d of inputFieldArray.area" style="padding-bottom: 5px;">
                                                <span class="input-group-text">{{d.placeholder}}</span>
                                                <input type="text" class="form-control" aria-label="With textarea"
                                                        [(ngModel)]="d.unitName"
                                                        name="{{d.name}}">
                                                <select class="form-select" style="max-width: max-content;"  [(ngModel)]="d.areaCount" name="areaCount" >  
                                                    <option *ngFor="let area of unitAreaArr" value={{area}} >
                                                    {{area}}
                                                    </option>
                                                </select>
                                            </div> -->
                                            <a class="btn btn-primary text-center align-self-end" (click) = "confirmAreaNames()">Next</a> 
                                        </form>                              
                                    </fieldset>
                                </form>
                                <!-- <div class="project-title" *ngIf = "step==5">
                                    <h5 style="color: rgb(59, 151, 255);"> <span class="text-muted">Project:</span> {{projectName}}</h5> <br>
                                    
                                    <fieldset >
                                        <h6 class="fs-title">Lidar Scan Files (zipped) </h6>
                                        <div class="row" >
                                            <div class="col text-left" >
                                                <p  class="file-selector"  
                                                    style=" height:30px; color: cornflowerblue;" >
                                                       <a onclick="document.getElementById('fileUpld').click()"> Upload File </a>
                                                </p>
                                                <input  type="file"    
                                                        id="fileUpld" 
                                                        name="fileUpld"
                                                       
                                                        #fileUpld 
                                                        (change)="processFile(fileUpld)" 
                                                        style="display:none" />   
                                            </div>                                           
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                                                <p>{{fileName}}</p>
                                            </div>
                                            <div class="col-sm-3" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                                                <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                        <div class="row" *ngIf="isSaving || fileProcessed">
                                            <div
                                            class="progress-bar progress-bar-info"
                                            role="progressbar"
                                            attr.aria-valuenow="{{ progress }}"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            [ngStyle]="{ width: progress + '%' }"
                                            >
                                            {{ progress }}%
                                        </div>
                                        </div>
                                        <div *ngIf="unitCountSelected">
                                            <select class="form-select" aria-label="unit select"  id="unit_count" (change)="fileUnit($event.target)">
                                                <option *ngFor="let title of unitCount" [value]="title">
                                                    {{title}}
                                                </option>   
                                            </select>
                                        </div>
                                        <button class="btn btn-primary" type="button" (click)="onSave()">Upload</button>
                                    </fieldset>
                                </div>    -->
                                
                                <a class="btn btn-primary text-center" *ngIf = "projectConfirmed && projectExists" (click) = "loadExistingProject()">Open Existing Project</a>
                                <!-- <a href="#" class="btn btn-primary" [routerLink]="['../newproject',clientId]">Create Project</a> -->
                                
                                <!-- <a href="#" class="btn btn-primary" >Create Project</a> -->
                            </div>
                            <div class="card-footer text-muted" *ngIf="currentUser">
                            Project admin: {{currentUser.attributes.given_name}} {{currentUser.attributes.family_name}}
                            </div>
                        </div>
                    </div>
                    <div class="col"  style="width: 80%; "*ngIf = "step==5">
                        <div class="card" style="width: 100%; height: 80vh;">
                            <div class="card-header" style="border-color: #bbd7f5;">
                            {{orgName}}
                            </div>
                            <div class="card-body">
                                <div style="padding-bottom: 8px; text-align: left;">
                                    <h5 class="card-title" style="color: rgb(59, 151, 255);" > <span class="text-muted">Project:</span> {{projectName}}</h5>
                                    <p ><span class="text-muted">Admin: </span>{{currentUser.attributes.given_name}} {{currentUser.attributes.family_name}}</p>
                                    <p ><span class="text-muted">Start: </span>{{projectStartDate}}</p>
                                    <p ><span class="text-muted">No. of {{unitTitle}}: </span>{{projectUnitCount}}</p>
                                    <!-- <p *ngFor="let d of inputFieldArray"><span class="text-muted">Start: </span>{{projectStartDate}}</p> -->
                                    <table class="table table-striped">
                                        <thead style="font-weight: 200; color: #4d4d4d;">
                                          <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">{{unitTitle | titlecase}}</th>
                                            <th scope="col">{{areaTitle}}</th>
                                          </tr>
                                        </thead>
                                        <tbody *ngFor="let t of hierarchyArr; index as i">
                                            <tr >
                                                <th>{{i+1}}</th>
                                                <td>{{t.unitName}}</td>
                                                <td>
                                                    <tr *ngFor="let tc of t.area">
                                                        <div>{{tc.areaName}}</div>
                                                    </tr>
                                                </td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                    <a class="btn btn-primary text-center justify-content-center" *ngIf="!submitMsg" (click) = "confirmProjectDetails()">Submit</a>
                                    <p *ngIf="submitMsg">Project created! You can now upload scans from your Project List.</p>
                                    <a class="btn btn-primary text-center justify-content-center" *ngIf="submitMsg" [routerLink]="['../../overview']">Project List</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col"  style="width: 10%; ">
                    </div>
                    <!-- <div class="col"  *ngIf="projectConfirmed" style="width: 10%; ">
                        <h2 *ngIf="!fileList">No files uploaded yet!</h2>
                    
                      <table class="table table-striped " *ngIf="fileList">
                        <thead>
                          <tr>
                            <th scope="col">Filename</th>
                            <th scope="col">Status</th>
                            <th scope="col">Category</th>
                            <th scope="col">Uploaded by</th>
                            <th scope="col">Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <hr *ngIf="fileList">
                            <tr class="all-users" *ngFor="let users of fileList">
                              <th scope="row">{{users.fileName}}</th>
                              <td>{{users.processing}}</td>
                              <td>{{users.category}}</td>
                              <td>{{users.updatedBy}}</td>
                              <td>{{users.rootFileName}}</td>
                            </tr>
                        </tbody>
                      </table>
                    
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>






<!-- <div class="row">
    <div class="col-8">
      <label class="btn btn-default p-0">
        <input type="file" (change)="selectFile($event)" />
      </label>
    </div>
  
    <div class="col-4">
      <button
        class="btn btn-success btn-sm"
        [disabled]="!selectedFiles"
        (click)="upload()"
      >
        Upload
      </button>
    </div>
  </div>
  
  <div *ngIf="currentFile" class="progress my-3">

  </div>
  
  <div *ngIf="message1" class="alert alert-secondary" role="alert">
    {{ message1 }}
  </div>
  
  <div class="card mt-3">
    <div class="card-header">List of Files</div>
    <ul class="list-group list-group-flush">
      <li *ngFor="let file of fileInfos | async" class="list-group-item">
        <a href="{{ file.url }}">{{ file.name }}</a>
      </li>
    </ul>
  </div> -->
  