export * from './project.service';
import { ProjectService } from './project.service';
export * from './scan.service';
import { ScanService } from './scan.service';
export * from './upload.service';
import { UploadService } from './upload.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './viewer.service';
import { ViewerService } from './viewer.service';
export const APIS = [ProjectService, ScanService, UploadService, UserService, ViewerService];
