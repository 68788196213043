import { Component, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UserService } from 'scantobim-openapi-frontend';
// import { faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import { faCheckDouble} from '@fortawesome/free-solid-svg-icons';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-collab',
  templateUrl: './modal-collab.component.html',
  styleUrls: ['./modal-collab.component.css']
})
export class ModalCollabComponent implements OnInit {

  subscription1!: Subscription
  @Input() public clientId: any;
  userList: any = []
  // faCheck = faCheckCircle
  faCheckDouble = faCheckDouble
  freshCollabList: any = []
  userAccessModified: boolean = false;
  constructor(private _NgbActiveModal: NgbActiveModal,
              private router: Router,
              private userService: UserService) { }
              
  ngOnInit(): void {
    //this.modalService.open(content, { centered: true });
    this.getOrgUsers()
  }
  get activeModal() {
    return this._NgbActiveModal;
  }
  getOrgUsers() {
    this.userList = []
    if(this.subscription1) this.subscription1.unsubscribe()
    this.subscription1 = this.userService.getUserByOrg(this.clientId).subscribe( {
      next: (v) => { 
        for(var i=0;i<v.length;i++) {
          var collabUser = {
            name: v[i].name,
            email: v[i].email,
            admin: v[i].isAdmin ? true : false,
            editor: v[i].isEditor ? true : false,
            newEditor: false,
            ismodified: false
          }
          this.userList.push(collabUser)
        }
      }
    })
  }
  pushEditor(user: any) {
    // this.freshCollabList.push(user.email)
    for(var i=0;i<this.userList.length;i++) {
      if(this.userList[i].email===user.email) {
        this.userList[i].newEditor=true
        this.userList[i].ismodified=true
      }
    }
  }
  popEditor(user: any) {
    for(var i=0;i<this.userList.length;i++) {
      if(this.userList[i].email===user.email) {
        this.userList[i].newEditor=false
        this.userList[i].ismodified=false
      }
    }
  }
  addCollab() {
    var userUp
    var userUpArr = []
    for(var i=0;i<this.userList.length;i++) {
      if(this.userList[i].ismodified) {
        if(this.userList[i].newEditor && !this.userList[i].editor) {
          userUp = {
            name: this.userList[i].name,
            email: this.userList[i].email,
            isEditor: 1,
          }
          userUpArr.push(userUp)
        } else if(!this.userList[i].newEditor && this.userList[i].editor) {
          userUp = {
            name: this.userList[i].name,
            email: this.userList[i].email,
            isEditor: 0,
          }
          userUpArr.push(userUp)
        }
      }
    }
    if(userUpArr.length!=0) {
      this.userService.updateUserEditor(userUpArr,this.clientId).subscribe(v => {
        this.getOrgUsers()
        this.userAccessModified = true
      })
    }
    
  }
}
